import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DummyService } from '../../services/dummy.service';
import { ClubFibexFeedingService } from '../../services/club-fibex-feeding.service';
import { HelperService } from '../../services/helper.service';
import { RoutingAppService } from '../../services/routing-app.service';
export class FilterComponent {
    constructor(router, modalController, dummy, _routing, clubFibexService, helper) {
        this.router = router;
        this.modalController = modalController;
        this.dummy = dummy;
        this._routing = _routing;
        this.clubFibexService = clubFibexService;
        this.helper = helper;
        this.groupCategory = [];
        this.activeCat = [];
        this.rate = 'all';
        this.price = 'all';
        this.rangePrices = ['1$', '10$', '50$', '100$', '+100$'];
        this.stars = [];
        this.categories = [];
        this.StarsSelected = [];
        this.minRange = '0';
        this.maxRange = '1000';
        this.initFn();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.stars = this.dummy.starsFilter;
            // this.categories = this.dummy.categoriesStack
            this.categories = yield this.clubFibexService.GetCategories();
            console.log("categories", this.categories);
        });
    }
    addActiveCat(category) {
        const index = this.activeCat.findIndex(cat => cat.idEmpCat === category.idEmpCat);
        if (index === -1) {
            this.activeCat.push(category);
        }
        else {
            this.activeCat = this.activeCat.filter(cat => cat.idEmpCat !== category.idEmpCat);
        }
    }
    addActiveStar(star) {
        let index = this.StarsSelected.findIndex(sta => sta.id === star.id);
        if (index === -1) {
            this.StarsSelected.push(star);
        }
        else {
            this.StarsSelected = this.StarsSelected.filter(element => element.id !== star.id);
        }
    }
    initFn() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const categories = yield this.clubFibexService.GetCategories();
            this.groupCategory = categories.map((category) => category.nombre);
        });
    }
    onBackNavigation() {
        this.modalController.dismiss();
    }
    onNavigation() {
        const arrArg = [];
        this.activeCat.forEach(element => {
            if (element.idEmpCat)
                arrArg.push(element.idEmpCat);
        });
        const qParam = arrArg.join('--');
        // console.log(qParam)
        // console.log(qParam.length)
        this.modalController.dismiss();
        this.router.navigate(['/club-fibex/product-list'], {
            queryParams: {
                qCategory: qParam,
                qPricing: `${this.minRange}--${this.maxRange}`
            },
            replaceUrl: true,
        });
    }
    setStarSelected(item) {
        this.StarsSelected = item.id;
    }
    trigger(ev) {
        const { detail } = ev;
        const { value } = detail;
        this.minRange = `${value.lower}`;
        this.maxRange = `${value.upper}`;
    }
    hasSelectedCat(category) {
        return (this.activeCat.includes(category));
    }
    hasSelectedStar(star) {
        return (this.StarsSelected.includes(star));
    }
}
