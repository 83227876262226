import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../environments/environment.prod';
import { IOneSignal } from '../interfaces/IOneSignal';
import { SeguridadDatos } from './bscript.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {
  dni: any;
  device$ = new EventEmitter<string>();
  private sign$ = new Subject<any>();
  
  constructor(
    private http: HttpClient,
    private security: SeguridadDatos
  ) { }

  oneSignalPost(OneSignal: IOneSignal){
    const headers ={
      'TokenAuthPlataform': env.TokenBackendFull,
      'db' : this.security.encrypt(env.NameBD),
      'table': this.security.encrypt(env.one_signal_tbl),
      'Authorization': `${'Basic ' + btoa(`${env.UserBackendFull}:${env.PasswordBackendFull}`)}`,
      'x-keys-to-add-id': '["idstr"]', 
      'x-keys-of-arrays': '[]', 
      'x-relations': 'false', 
      'Content-Type': 'application/json'
    }

    console.log(headers)
    this.http.post(`${env.ApiBackendThomas}create-info`, OneSignal, {headers}).subscribe(res => console.log(res));
  }

  async oneSignalGet(subscriberNumb:string, platform: string){
    const headers ={
      'TokenAuthPlataform': env.TokenBackendFull,
      'db' : this.security.encrypt(env.NameBD),
      'table': this.security.encrypt(env.one_signal_tbl),
      'type':this.security.encrypt(env.type_specific_search),
      'campo':this.security.encrypt(env.field_specific_info),
      'valor':this.security.encrypt(subscriberNumb),
      'campo2':this.security.encrypt(env.field_specific_info2),
      'valor2':this.security.encrypt(platform),
      'Authorization': `${'Basic ' + btoa(`${env.UserBackendFull}:${env.PasswordBackendFull}`)}`,
      'Content-Type': 'application/json'
    }

    return await this.http.get(env.ApiBackendThomas,{headers})
  }

  async oneSignalPut(OneSignal: IOneSignal){
    const headers ={
      'TokenAuthPlataform': env.TokenBackendFull,
      'db' : this.security.encrypt(env.NameBD),
      'table': this.security.encrypt(env.one_signal_tbl),
      'campo':this.security.encrypt(env.field_specific_info),
      'campo2':this.security.encrypt(env.field_specific_info2),
      'valor': this.security.encrypt(OneSignal.abonado),
      'Authorization': `${'Basic ' + btoa(`${env.UserBackendFull}:${env.PasswordBackendFull}`)}`,
      'x-multiple-update': 'false',
      'x-elements-obj': '[]',
      'x-attr-duplicate': '[]',
      'Content-Type': 'application/json'
    }

    this.http.put(`${env.ApiBackendThomas}update-info`, OneSignal, {headers}).subscribe(res => console.log(res));
  }

  emitSign(){
    this.sign$.next('logout');
  }

  getSign(): Observable<any>{
    return this.sign$.asObservable();
  }
}
