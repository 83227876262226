/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-cuenta.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./list-cuenta.page";
import * as i6 from "../../../services/cedula-storage.service";
import * as i7 from "../../../services/manage-cuenta.service";
var styles_ListCuentaPage = [i0.styles];
var RenderType_ListCuentaPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ListCuentaPage, data: {} });
export { RenderType_ListCuentaPage as RenderType_ListCuentaPage };
function View_ListCuentaPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "ion-item", [["tappable", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.elegirCuenta(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonItem_0, i2.RenderType_IonItem)), i1.ɵdid(1, 49152, null, 0, i3.IonItem, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-icon", [["color", "primary"], ["name", "ios-list-box"], ["slot", "start"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(3, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-text", [["color", "primary"]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(5, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵted(6, 0, ["", ""]))], function (_ck, _v) { var currVal_0 = "primary"; var currVal_1 = "ios-list-box"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "primary"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.identidad; _ck(_v, 6, 0, currVal_3); }); }
export function View_ListCuentaPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 10, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(5, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Seleccione una cuenta123"])), (_l()(), i1.ɵeld(7, 0, null, 0, 5, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(8, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(9, 0, null, 0, 3, "ion-button", [["color", "tertiary"], ["shape", "round"], ["size", "small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(10, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], shape: [1, "shape"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 1, "ion-icon", [["name", "close"], ["slot", "start"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(12, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "ion-content", [["class", "animated fadeIn"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(14, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ListCuentaPage_1)), i1.ɵdid(16, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "primary"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "tertiary"; var currVal_2 = "round"; var currVal_3 = "small"; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "close"; _ck(_v, 12, 0, currVal_4); var currVal_5 = _co.cedulas; _ck(_v, 16, 0, currVal_5); }, null); }
export function View_ListCuentaPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list-cuenta", [], null, null, null, View_ListCuentaPage_0, RenderType_ListCuentaPage)), i1.ɵdid(1, 114688, null, 0, i5.ListCuentaPage, [i3.ModalController, i6.CedulaStorage, i7.ManageCuentaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListCuentaPageNgFactory = i1.ɵccf("app-list-cuenta", i5.ListCuentaPage, View_ListCuentaPage_Host_0, {}, {}, []);
export { ListCuentaPageNgFactory as ListCuentaPageNgFactory };
