import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";
import { LocalStorageService } from '../../../services/local-storage.service';
import { VerifyInternetStatusService } from '../../../services/verify-internet-status.service';
import { EstadisticasService } from '../../../services/estadisticas.service';
@Component({
  selector: 'app-reporte-averia-modal',
  templateUrl: './reporte-averia-modal.page.html',
  styleUrls: ['./reporte-averia-modal.page.scss'],
})
export class ReporteAveriaModalPage implements OnInit {
  public onLine: boolean = true;
  public CedulaActual: any;
  public urlReporte: string = 'https://reporte.fibextelecom.net/?app=&dni=';
  public secureUrl: string = '';
  constructor(
    private modalCtrl: ModalController,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _localStorageService: LocalStorageService,
    private _internetService: VerifyInternetStatusService,
    private _estadisticas: EstadisticasService,
  ) {
    this._estadisticas.RegisterAccion("13")
    this.CedulaActual = this._localStorageService.get('CedulaActual');
    this.secureUrl = `${this.urlReporte}${this.CedulaActual.identidad}`;
    this._SubscriptionGraphqlService.OpSelect.subscribe((op: any) => {
      if (op.option === "op3-2") {
        this.closeModal();
      }
    })
    this._internetService.testNetworkConnection()
    this.onLine = this._internetService.onLine;
    this._internetService.internetState.subscribe((data: boolean) => {
      this.onLine = data;
      this._internetService.verifyConection(this.onLine);
      if (!this.onLine) {
        this.closeModal()
      }
    });
  }

  ngOnInit() {
    this._SubscriptionGraphqlService.OpSelect.subscribe((op: any) => {
      switch (op.option) {
        case "op3-2-1":
          this.closeModal();
          break;

        default:
          break;
      }

    })

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
