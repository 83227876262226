import { Component, Input, OnInit } from '@angular/core';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { ProductInterface } from '../../../../interfaces/product.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card3',
  templateUrl: './card3.component.html',
  styleUrls: ['./card3.component.scss'],
})
export class Card3Component implements OnInit {

  @Input() item: ProductInterface | undefined | null;
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() image: string = '';

  constructor(
    private ClubFibexServ: ClubFibexFeedingService,
    private router: Router
  ) { }

  ngOnInit() {}
  
  public async addFavoriteItem(): Promise<void> {
    if (this.item) await this.ClubFibexServ.handleProductFav(this.item)
  }
  public goToRestDetail(): void {
    this.router.navigate(['club-fibex', 'product-details', this.item.id, false])
  }
  
  public subtractToNumber(num1: unknown, num2: unknown): number {
    return Number(num1) - Number(num2);
  }
}
