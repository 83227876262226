import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number'
})
export class NumberPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if( args.length === 0 || args === undefined ) value;

    return value.replace(/\D/g, '');
  }

}
