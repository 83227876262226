/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reporte-averia-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/url.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "@angular/common";
import * as i7 from "./reporte-averia-modal.page";
import * as i8 from "../../../services/subscription-graphql.service";
import * as i9 from "../../../services/local-storage.service";
import * as i10 from "../../../services/verify-internet-status.service";
import * as i11 from "../../../services/estadisticas.service";
var styles_ReporteAveriaModalPage = [i0.styles];
var RenderType_ReporteAveriaModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_ReporteAveriaModalPage, data: {} });
export { RenderType_ReporteAveriaModalPage as RenderType_ReporteAveriaModalPage };
function View_ReporteAveriaModalPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "iframe", [["style", "\n      width:100%;\n      height: 99%;\n    "]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.secureUrl)); _ck(_v, 0, 0, currVal_0); }); }
export function View_ReporteAveriaModalPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UrlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 11, "ion-header", [], null, null, null, i4.View_IonHeader_0, i4.RenderType_IonHeader)), i1.ɵdid(2, 49152, null, 0, i5.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 9, "ion-toolbar", [["color", "primary"]], null, null, null, i4.View_IonToolbar_0, i4.RenderType_IonToolbar)), i1.ɵdid(4, 49152, null, 0, i5.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 4, "ion-buttons", [["slot", "start"]], null, null, null, i4.View_IonButtons_0, i4.RenderType_IonButtons)), i1.ɵdid(6, 49152, null, 0, i5.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "ion-back-button", [["text", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_IonBackButton_0, i4.RenderType_IonBackButton)), i1.ɵdid(8, 49152, null, 0, i5.IonBackButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { text: [0, "text"] }, null), i1.ɵdid(9, 16384, null, 0, i5.IonBackButtonDelegate, [[2, i5.IonRouterOutlet], i5.NavController], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "ion-title", [], null, null, null, i4.View_IonTitle_0, i4.RenderType_IonTitle)), i1.ɵdid(11, 49152, null, 0, i5.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Reportar aver\u00EDa"])), (_l()(), i1.ɵeld(13, 0, null, null, 3, "ion-content", [], null, null, null, i4.View_IonContent_0, i4.RenderType_IonContent)), i1.ɵdid(14, 49152, null, 0, i5.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ReporteAveriaModalPage_1)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "primary"; _ck(_v, 4, 0, currVal_0); var currVal_1 = ""; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.secureUrl; _ck(_v, 16, 0, currVal_2); }, null); }
export function View_ReporteAveriaModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reporte-averia-modal", [], null, null, null, View_ReporteAveriaModalPage_0, RenderType_ReporteAveriaModalPage)), i1.ɵdid(1, 114688, null, 0, i7.ReporteAveriaModalPage, [i5.ModalController, i8.SubscriptionGraphqlService, i9.LocalStorageService, i10.VerifyInternetStatusService, i11.EstadisticasService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReporteAveriaModalPageNgFactory = i1.ɵccf("app-reporte-averia-modal", i7.ReporteAveriaModalPage, View_ReporteAveriaModalPage_Host_0, {}, {}, []);
export { ReporteAveriaModalPageNgFactory as ReporteAveriaModalPageNgFactory };
