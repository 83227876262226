import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EstadisticasService } from '../../../services/estadisticas.service';
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";

@Component({
  selector: 'app-suport-modal',
  templateUrl: './suport-modal.page.html',
  styleUrls: ['./suport-modal.page.scss'],
})

export class SuportModalPage implements OnInit {
  @Input() data: any;
  ShowDetail:Boolean = false
  Contador: number = 1
  constructor(
    private modalCtrl: ModalController,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _estadisticas: EstadisticasService,
  ) {
    this._estadisticas.RegisterAccion("14")
   }

  ngOnInit() {

    this._SubscriptionGraphqlService.OpSelect.subscribe((op:any)=>{
      switch (op.option) {
        case "op3-1-1":
          this.closeModal();
          break;
      
        default:
          break;
      }

    })

  }

  ViewCardDetail() {
    try {
      if (this.Contador === 10) {

        if (this.ShowDetail === true) {
          this.ShowDetail = false
        } else if (this.ShowDetail === false) {
          this.ShowDetail = true
        }

        this.Contador = 1

      } else {
        this.Contador = this.Contador + 1
      }

    } catch (error) {
      console.error(error)
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
