import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { ConsultasService } from './consultas.service';
import { LocalStorageService } from './local-storage.service';
import { UserAuthenticationService } from './user-authentication.service';
import { LogErrorsService } from './log-errors.service';
import { SeguridadDatos } from './bscript.service';
import { OptionMenu } from '../providers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./local-storage.service";
import * as i3 from "./user-authentication.service";
import * as i4 from "./log-errors.service";
import * as i5 from "./bscript.service";
import * as i6 from "./consultas.service";
export class EstadisticasService {
    constructor(_http, _localStorageService, _authentication, _logs, security, _consultas) {
        this._http = _http;
        this._localStorageService = _localStorageService;
        this._authentication = _authentication;
        this._logs = _logs;
        this.security = security;
        this._consultas = _consultas;
        this.RutaActual = '';
        this.ListAcciones = [];
        this.InitTimer();
    }
    GetAcciones() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const headersData = {
                        directory: "Consultas",
                        method: "GetActionsApp",
                        token: environment.TokenUpdate,
                        platform: "App"
                    };
                    this.security.EncrypDataHash(headersData).then((headers) => {
                        // peticion legal de los datos y encabezados encriptados
                        this._http.get(environment.URLApi, { headers }).subscribe(data => {
                            resolve(this.security.Descryp(data));
                        }, (error) => {
                            this._logs.insertarError(error.message);
                            reject(error);
                        });
                    }).catch((err) => console.error(err));
                }
                catch (error) {
                    this._logs.insertarError(error.message);
                }
            }));
        });
    }
    GetIdAccion(Ruta) {
        try {
            const ListMenu = this._consultas.MenuApp || OptionMenu;
            const OpcionSelect = ListMenu.find((FO) => FO.url === Ruta);
            if (OpcionSelect) {
                const Accion = this.ListAcciones.find((Fa) => Fa.accion === OpcionSelect.title);
                if (Accion) {
                    return Accion.idAccion;
                }
                else {
                    return null;
                }
            }
            else {
                return null;
            }
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
    GuardarAcciones() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                //const data = Object.values(this._localStorageService.get("acciones")) || [];
                const DataAcciones = this._localStorageService.get("acciones");
                // console.log(DataAcciones)
                if (DataAcciones && DataAcciones.length > 0) {
                    const headersData = {
                        directory: "Envio",
                        method: "RegisterActionsApp",
                        token: environment.TokenUpdate,
                        platform: "App"
                    };
                    this.security.EncrypDataHash(headersData).then((headers) => {
                        // peticion legal de los datos y encabezados encriptados
                        this.security.EncrypDataHash(DataAcciones).then((DataBody) => {
                            this._http.post(environment.URLApi, DataBody, { headers }).subscribe(data => {
                                console.log("Recibi la data de GuardarAcciones");
                                console.log(data);
                                this._localStorageService.remove("acciones");
                            }, (error) => {
                                this._logs.insertarError(error.message);
                                console.error(error);
                            });
                        }).catch((err) => console.error(err));
                    }).catch((err) => console.error(err));
                }
            }
            catch (error) {
                this._logs.insertarError(error.message);
            }
        });
    }
    RegisterAccion(Ruta) {
        try {
            let AccionesRegistradas = this._localStorageService.get("acciones") || [];
            let Fecha = new Date();
            Fecha = Fecha.getUTCFullYear() + '-' +
                ('00' + (Fecha.getUTCMonth() + 1)).slice(-2) + '-' +
                ('00' + Fecha.getUTCDate()).slice(-2) + ' ' +
                ('00' + Fecha.getHours()).slice(-2) + ':' +
                ('00' + Fecha.getMinutes()).slice(-2) + ':' +
                ('00' + Fecha.getUTCSeconds()).slice(-2);
            const user = this._authentication.getUser();
            const cedulaCliente = this._localStorageService.get("cedulaCliente");
            let contrato;
            if (user || cedulaCliente) {
                if (cedulaCliente) {
                    contrato = cedulaCliente.NContrato;
                }
                else {
                    const cedulas = this._localStorageService.get("cedulas");
                    if (cedulas && cedulas[0]) {
                        contrato = cedulas[0].numContratos[0];
                    }
                    else {
                        contrato = user.identidad;
                    }
                }
                const Id_Accion = this.GetIdAccion(Ruta);
                // console.log("me llego el Id_Accion " + Id_Accion)
                if (Id_Accion != null) {
                    // console.log("Registrar Accion ")
                    AccionesRegistradas.push({
                        idAccion: Id_Accion,
                        identidad: user.identidad,
                        contrato,
                        fecha: Fecha,
                    });
                    // console.log(AccionesRegistradas)
                    this._localStorageService.set("acciones", AccionesRegistradas);
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    InitTimer() {
        setTimeout(() => {
            this.GuardarAcciones();
        }, 1200000);
    }
}
EstadisticasService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EstadisticasService_Factory() { return new EstadisticasService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.UserAuthenticationService), i0.ɵɵinject(i4.LogErrorsService), i0.ɵɵinject(i5.SeguridadDatos), i0.ɵɵinject(i6.ConsultasService)); }, token: EstadisticasService, providedIn: "root" });
