/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notification-modal.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "@angular/common";
import * as i5 from "./notification-modal.page";
import * as i6 from "../../../services/subscription-graphql.service";
import * as i7 from "../../../services/consultas.service";
import * as i8 from "../../../services/local-storage.service";
var styles_NotificationModalPage = [i0.styles];
var RenderType_NotificationModalPage = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationModalPage, data: {} });
export { RenderType_NotificationModalPage as RenderType_NotificationModalPage };
function View_NotificationModalPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ion-buttons", [["slot", "end"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(1, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 3, "ion-button", [["color", "tertiary"], ["shape", "round"], ["size", "small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(3, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], shape: [1, "shape"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "ion-icon", [["name", "close"], ["slot", "start"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(5, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null)], function (_ck, _v) { var currVal_0 = "tertiary"; var currVal_1 = "round"; var currVal_2 = "small"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "close"; _ck(_v, 5, 0, currVal_3); }, null); }
function View_NotificationModalPage_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ion-button", [["class", "custom"], ["icon-left", ""], ["shape", "round"], ["size", "full"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.RegisterViewCobranza(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(1, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { shape: [0, "shape"], size: [1, "size"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "ion-ripple-effect", [], null, null, null, i2.View_IonRippleEffect_0, i2.RenderType_IonRippleEffect)), i1.ɵdid(3, 49152, null, 0, i3.IonRippleEffect, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, [" Aceptar "]))], function (_ck, _v) { var currVal_0 = "round"; var currVal_1 = "full"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_NotificationModalPage_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "ion-card", [["class", "bg-white"]], null, null, null, i2.View_IonCard_0, i2.RenderType_IonCard)), i1.ɵdid(1, 49152, null, 0, i3.IonCard, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 4, "ion-card-content", [["class", "ion-padding"]], null, null, null, i2.View_IonCardContent_0, i2.RenderType_IonCardContent)), i1.ɵdid(3, 49152, null, 0, i3.IonCardContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "ion-text", [], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(5, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(6, 0, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, 0, 3, "ion-text", [["style", "margin-left: inherit;"]], null, null, null, i2.View_IonText_0, i2.RenderType_IonText)), i1.ɵdid(8, 49152, null, 0, i3.IonText, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(9, 0, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NotificationModalPage_3)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.ShowBoton; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.mensaje; _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.Fecha, "dd/MM/yyyy H:mm")); _ck(_v, 9, 0, currVal_1); }); }
export function View_NotificationModalPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(2, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(3, 0, null, 0, 6, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(4, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "ion-title", [], null, null, null, i2.View_IonTitle_0, i2.RenderType_IonTitle)), i1.ɵdid(6, 49152, null, 0, i3.IonTitle, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵted(-1, 0, ["Notificaci\u00F3n"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NotificationModalPage_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "ion-content", [["class", "ion-padding animated fadeIn fast"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(11, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_NotificationModalPage_2)), i1.ɵdid(13, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "primary"; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.HideBtnClose; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.data; _ck(_v, 13, 0, currVal_2); }, null); }
export function View_NotificationModalPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notification-modal", [], null, null, null, View_NotificationModalPage_0, RenderType_NotificationModalPage)), i1.ɵdid(1, 114688, null, 0, i5.NotificationModalPage, [i3.ModalController, i6.SubscriptionGraphqlService, i7.ConsultasService, i8.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationModalPageNgFactory = i1.ɵccf("app-notification-modal", i5.NotificationModalPage, View_NotificationModalPage_Host_0, { data: "data" }, {}, []);
export { NotificationModalPageNgFactory as NotificationModalPageNgFactory };
