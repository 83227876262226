<ion-header>
  <ion-toolbar color="primary">
    <ion-title (click)="ViewCardDetail()">Detalle de orden</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon> 
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card class="bg-white" style="margin-bottom: 15px;">
    <ion-card-content class="ion-no-padding" style="padding: 15px; padding-left: 0;">
        <ion-item *ngIf="data.nro_orden">
          <ion-label class="ion-no-padding ion-no-margin text-primary text08">
            <ion-text color="primary">Número de orden:</ion-text>
          </ion-label>
          <ion-text color="primary"><strong>{{data.nro_orden}}</strong></ion-text>
        </ion-item>

        <ion-item *ngIf="data.id_orden">
          <ion-label class="ion-no-padding ion-no-margin text-primary text08">
            <ion-text color="primary">Id orden:</ion-text>
          </ion-label>
          <ion-text color="primary"><strong>{{data.id_orden}}</strong></ion-text>
        </ion-item>

        <ion-item *ngIf="data.nombre_tipo_orden">
          <ion-label class="ion-no-padding ion-no-margin text-primary text08">
            <ion-text color="primary">Tipo de orden:</ion-text>
          </ion-label>
          <ion-text color="primary"><strong>{{data.nombre_tipo_orden}}</strong></ion-text>
        </ion-item>

        <ion-item *ngIf="data.nombre_det_orden">
          <ion-label class="ion-no-padding ion-no-margin text-primary text08">
            <ion-text color="primary">Orden:</ion-text>
          </ion-label>
          <ion-text color="primary"><strong>{{data.nombre_det_orden}}</strong></ion-text>
        </ion-item>

        <ion-item *ngIf="data.fecha_orden">
          <ion-label class="ion-no-padding ion-no-margin text-primary text08">
            <ion-text color="primary">Fecha de orden:</ion-text>
          </ion-label>
          <ion-text color="primary"><strong>{{data.fecha_orden}}</strong></ion-text>
        </ion-item>

        <ion-item *ngIf="data.fecha_cierre">
          <ion-label class="ion-no-padding ion-no-margin text-primary text08">
            <ion-text color="primary">Fecha cierre:</ion-text>
          </ion-label>
          <ion-text color="primary"><strong>{{data.fecha_cierre}}</strong></ion-text>
        </ion-item>

        <ion-item *ngIf="data.nombre_grupo">
          <ion-label class="ion-no-padding ion-no-margin text-primary text08">
            <ion-text color="primary">Nombre Grupo:</ion-text>
          </ion-label>
          <ion-text color="primary"><strong>{{data.nombre_grupo}}</strong></ion-text>
        </ion-item>

        <ion-item *ngIf="data.login_emi">
          <ion-label class="ion-no-padding ion-no-margin text-primary text08">
            <ion-text color="primary">Operador:</ion-text>
          </ion-label>
          <ion-text color="primary"><strong>{{data.login_emi}}</strong></ion-text>
        </ion-item>

        <ion-item *ngIf="data.status_orden">
          <ion-label class="ion-no-padding ion-no-margin text-primary text08">
            <ion-text color="primary">Estatus:</ion-text>
          </ion-label>
          <ion-text color="primary"><strong>{{data.status_orden}}</strong></ion-text>
        </ion-item>
        <ion-item *ngIf="data.detalle_orden && ShowDetail">
          <ion-col style="padding: 0;">
            <ion-label class="ion-no-padding ion-no-margin text-primary text08">
              <ion-text color="primary">Detalle de orden:</ion-text>
            </ion-label>
            <ion-text color="primary"><strong>{{data.detalle_orden}}</strong></ion-text>
          </ion-col>
        </ion-item>

        <ion-item *ngIf="data.comentario_orden">
          <ion-col style="padding: 0;">
            <ion-label class="ion-no-padding ion-no-margin text-primary text08">
  
              <ion-text color="primary">Comentario:</ion-text>
            </ion-label>
            <ion-text color="primary"><strong>{{data.comentario_orden}}</strong></ion-text>
          </ion-col>
        </ion-item>
    </ion-card-content>
  </ion-card>
</ion-content>