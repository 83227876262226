import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ConsultasService } from "../../../services/consultas.service";
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";
import { LocalStorageService } from '../../../services/local-storage.service';
import { LogErrorsService } from "../../../services/log-errors.service";
export class UpgrateServicePage {
    constructor(modal, fb, consultasS, _SubscriptionGraphqlService, _localStorageService, alertController, _logs) {
        this.modal = modal;
        this.fb = fb;
        this.consultasS = consultasS;
        this._SubscriptionGraphqlService = _SubscriptionGraphqlService;
        this._localStorageService = _localStorageService;
        this.alertController = alertController;
        this._logs = _logs;
        this.PlanActual = "";
        this.DisableButons = false;
        this.TengoDatos = false;
        this.UserInfo = this._localStorageService.get('user');
    }
    ngOnInit() {
        this._SubscriptionGraphqlService.OpSelect.subscribe((op) => {
            switch (op.option) {
                case "op5-3":
                    this.closeModal();
                    break;
                default:
                    break;
            }
        });
        this.consultasS.ListService().then((ResService) => {
            this.infoServicio = ResService;
            if (this.PlanActual.includes("PYME")) {
                this.ServiciosActuales = this.infoServicio.filter((service) => service.Categoria === "PYME");
            }
            else {
                this.ServiciosActuales = this.infoServicio.filter((service) => service.Categoria != "PYME");
            }
            this.formService = this.fb.group({
                serviceSelect: this.ServiciosActuales
            });
            this.TengoDatos = true;
        }).catch(error => {
            this._logs.insertarError(error.message);
        });
    }
    get serviceSelect() { return this.formService.get("serviceSelect"); }
    closeModal() {
        this.modal.dismiss(this.DisableButons);
    }
    AddUpgrade() {
        try {
            const pin = Math.random().toString(16).slice(3, 9);
            const DataService = Object.assign({ 'PlanActual': this.PlanActual, Accion: "UPGRATE" }, this.DatosUser, this.DatosEquipo, this.pack);
            this.consultasS.sendMail(this.UserInfo.email, "Confirmación de Upgrate", `Usted ha solicitado un Upgrate de servicios a ${DataService.Capacidad} con una mensualidad de ${DataService.Precio}. <br><br> Su código de verificación es: <strong>${pin}</strong><br><br> Si usted no solicito un upgrate de servicio puede que otra persona este intentando solicitarlo`).then((ResEmail) => {
                this.VerifyUpgrate(pin, this.UserInfo.email, DataService);
            }).catch((err) => {
                console.error(err);
            });
        }
        catch (error) {
            this._logs.insertarError(error.message);
        }
    }
    presentAlertMultipleButtons(header, msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: header,
                message: msg,
                buttons: ['Aceptar']
            });
            yield alert.present();
        });
    }
    VerifyUpgrate(Pin, Email, DataS) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const Pos = Email.indexOf('@');
                let HideLetter = '';
                for (let index = 0; index < Pos - 6; index++) {
                    HideLetter = HideLetter + '*';
                }
                const correo = `${Email.substring(0, 3)}${HideLetter}${Email.substring(Pos - 3, Email.length)}`;
                const alert = yield this.alertController.create({
                    header: 'Pin de confirmación',
                    message: `Introduzca el pin que le llegó al correo ${correo} para poder aplicar el upgrade`,
                    inputs: [
                        {
                            name: 'pin',
                            type: 'email',
                            placeholder: 'Pin de confirmación',
                        }
                    ],
                    buttons: [
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                            cssClass: 'secondary',
                        }, {
                            text: 'Confirmar',
                            handler: (data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                if (data.pin) {
                                    if (data.pin === Pin) {
                                        this.ApplyUpgrade(DataS);
                                    }
                                    else {
                                        this.presentAlertMultipleButtons('Error de confirmación', 'El pin ingresado es incorrecto');
                                    }
                                }
                                else {
                                    return;
                                }
                            })
                        }
                    ]
                });
                yield alert.present();
            }
            catch (error) {
                console.error(error);
            }
        });
    }
    ApplyUpgrade(DatosServicio) {
        try {
            if (DatosServicio) {
                this.consultasS.TurBoUpgrade(DatosServicio).then((Res) => {
                    this.DisableButons = true;
                    this.presentAlertMultipleButtons('Solicitud', 'Su solicitud de upgrade se encuentra en proceso, una vez que se aplique será contactado');
                    // alert("Su solicitud de upgrade se encuentra en proceso, una vez que se aplique será contactado")
                    this._localStorageService.set('SolicitudUpTur', new Date());
                }).catch(error => {
                    this._logs.insertarError(error.message);
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    }
}
