import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { EstadisticasService } from '../../../services/estadisticas.service';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-contratar-fibex-play',
  templateUrl: './contratar-fibex-play.page.html',
  styleUrls: ['./contratar-fibex-play.page.scss'],
})
export class ContratarFibexPlayPage implements OnInit {
  public formContratar: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private _estadisticas: EstadisticasService,
    private _localStorageService: LocalStorageService,
  ) {
    this._estadisticas.RegisterAccion("10");
    this.formContratar = this.fb.group({
      fullName: [''],
      identidad: [''],
      email: [''],
    })
  }

  get fullName() { return this.formContratar.get('fullName'); }
  get email() { return this.formContratar.get('email'); }
  get identidad() { return this.formContratar.get('identidad'); }

  ngOnInit() {
  }

  GetDataForm() {
    this._localStorageService.set("procesandoSolicitud", true);
    this.modalCtrl.dismiss("OK");
    this.closeModal();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
