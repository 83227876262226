<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'app.pages.favorites.title.header' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-text-center" class="animated fadeIn">
 
  <ion-card class="bg-white ion-padding  animated fadeInUp" *ngIf="permitirPagoMovil && !transferencia && !pagoMovil && !pagado">
    <ion-title><strong style="margin-left: auto; margin-right:auto;">Seleccione una opción de pago</strong></ion-title>
    <ion-button icon-left size="medium" class="ion-margin-bottom" expand="full" shape="round" color="dark" (click)="seleccionarPagoMovil()"
        tappable>
      Pago movil
    </ion-button>
    <ion-button icon-left size="medium" expand="full" shape="round" color="dark" (click)="seleccionarTransferencia()"
        tappable>
      Reportar transferencia
    </ion-button>
  </ion-card>
  <iframe
    *ngIf="secureUrl != '' && ((permitirPagoMovil === true && transferencia === true) || permitirPagoMovil === false)"
    id="reporte"
    [src]="secureUrl | url "
    style="
      width:100%;
      height: 99%;"
  ></iframe>

  <ion-card class="bg-white ion-padding  animated fadeInUp" *ngIf="permitirPagoMovil === true && pagoMovil === true && !pagado">
    <ion-title><strong style="margin-left: auto; margin-right:auto;">Pago movil</strong></ion-title>
    <form [formGroup]="onPagoMovilForm" class="list-form">
      <ion-item class="ion-no-padding animated fadeInUp">
         <ion-label position="floating">
          Numero de telefono:
        </ion-label> 
        <ion-input color="secondary" type="number" formControlName="numero_telefono"></ion-input>
      </ion-item>
      <p class="text08" *ngIf="onPagoMovilForm.get('numero_telefono').touched && onPagoMovilForm.get('numero_telefono').hasError('required')">
        <ion-text color="medium">
          El numero de telefono es requirido.
        </ion-text>
      </p>
      
     <!-- <ion-item *ngIf="bancos.length">
        <ion-label color="dark" position="stacked">Banco</ion-label>
        <ion-select formControlName="banco"  color="primary" multiple="false" cancelText="Cancelar" okText="Seleccionar">
          <ion-select-option 
            [value]="banco.nomBco" 
            *ngFor="let banco of bancos">
            {{banco.nomBco}}
          </ion-select-option>
        </ion-select>
      </ion-item>-->

      <ion-item>
        <ion-label color="dark" position="stacked">Banco</ion-label>
        <ion-select formControlName="banco" ngModel="Mercantil"  color="primary" multiple="false" cancelText="Cancelar" okText="Seleccionar">
          <ion-select-option value="Mercantil">
            Mercantil
          </ion-select-option>
        </ion-select>
      </ion-item>
      <p class="text08" *ngIf="onPagoMovilForm.get('banco').touched && onPagoMovilForm.get('banco').hasError('required')">
          <ion-text color="medium">
            El banco es requirido.
          </ion-text>
        </p>
      
      <ion-item class="ion-no-padding animated fadeInUp">
          <ion-label position="floating">
            Monto en Bs:
          </ion-label> 
         <ion-input color="secondary" type="number" formControlName="monto"></ion-input>
       </ion-item>
       <p class="text08" *ngIf="onPagoMovilForm.get('monto').touched && onPagoMovilForm.get('monto').hasError('required')">
         <ion-text color="medium">
           El monto es requerido.
         </ion-text>
       </p>
     <ion-button class="ion-margin-bottom ion-margin-top" icon-left size="medium" expand="full" shape="round" color="dark" (click)="solicitarPin()" tappable>
      Solicitar clave de pago
    </ion-button>
     
    </form>
    <div>
      <ion-button icon-left size="medium" expand="full" shape="round" color="dark" (click)="procesarPago()" [disabled]="!(onPagoMovilForm.valid && pin)" tappable>
        Pagar
      </ion-button>
    </div>
  </ion-card>

<div *ngIf="permitirPagoMovil === true && pagoMovil === true && pagado">

  <ion-card class="bg-white ion-padding  animated fadeInUp" *ngIf="failure">
    <ion-title style="padding: 5px; text-align: center; color:#e11e11;">
      <div style="display: flex; justify-content:center">
        <ion-icon name="close-circle" style="color:#e11e11;" size="large"></ion-icon>
      </div>
      ¡transacción Fallida!
    </ion-title>
    <ion-card-content class="ion-no-padding">
      <ion-item >
        <div>
          <ion-text color="primary">Número de Referencia:</ion-text>
          <br>
          <ion-text color="dark"><strong>34315512312</strong></ion-text>
        </div>
      </ion-item>

      <ion-item >
        <div>
          <ion-text color="primary">Fecha:</ion-text>
          <br>
          <ion-text color="dark"><strong>{{fecha}}</strong></ion-text>
        </div>
      </ion-item>

      <ion-item *ngIf="data">
        <div>
          <ion-text color="primary">Número de celular de origen:</ion-text>
          <br>
          <ion-text color="dark"><strong>{{data.numero_telefono}}</strong></ion-text>
        </div>
      </ion-item>

      <ion-item *ngIf="data">
        <div>
          <ion-text color="primary">Número de celular de destino:</ion-text>
          <br>
          <ion-text color="dark"><strong>04120202020</strong></ion-text>
        </div>
      </ion-item>

      <ion-item *ngIf="data">
        <div>
          <ion-text color="primary">Banco emisor:</ion-text>
          <br>
          <ion-text color="dark"><strong>{{data.banco}}</strong></ion-text>
        </div>
      </ion-item>
      
      <ion-item *ngIf="data">
        <div>
          <ion-text color="primary">Monto:</ion-text>
          <br>
          <ion-text color="dark"><strong>{{data.monto}} Bs</strong></ion-text>
        </div>
      </ion-item>
      <ion-item *ngIf="data">
        <div>
          <ion-text color="primary">Concepto:</ion-text>
          <br>
          <ion-text color="dark"><strong>Error en el banco receptor.</strong></ion-text>
        </div>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card class="bg-white ion-padding  animated fadeInUp" *ngIf="success">
    <ion-title style="padding: 5px; text-align: center">
      ¡Operación Exitosa!
      <div style="display: flex; justify-content:center">
        <ion-icon name="checkmark-circle" style="color:#1e8e6b;" size="large"></ion-icon>
      </div>
    </ion-title>
    <ion-card-content class="ion-no-padding">
      <ion-item >
        <div>
          <ion-text color="primary">Número de Referencia:</ion-text>
          <br>
          <ion-text color="dark"><strong>34315512312</strong></ion-text>
        </div>
      </ion-item>

      <ion-item >
        <div>
          <ion-text color="primary">Fecha:</ion-text>
          <br>
          <ion-text color="dark"><strong>{{fecha}}</strong></ion-text>
        </div>
      </ion-item>

      <ion-item *ngIf="data">
        <div>
          <ion-text color="primary">Número de celular de origen:</ion-text>
          <br>
          <ion-text color="dark"><strong>{{data.numero_telefono}}</strong></ion-text>
        </div>
      </ion-item>

      <ion-item *ngIf="data">
        <div>
          <ion-text color="primary">Número de celular de destino:</ion-text>
          <br>
          <ion-text color="dark"><strong>{{data.numero_telefono}}</strong></ion-text>
        </div>
      </ion-item>

      <ion-item *ngIf="data">
        <div>
          <ion-text color="primary">Banco emisor:</ion-text>
          <br>
          <ion-text color="dark"><strong>{{data.banco}}</strong></ion-text>
        </div>
      </ion-item>
      <ion-item *ngIf="data">
        <div>
          <ion-text color="primary">Banco receptor:</ion-text>
          <br>
          <ion-text color="dark"><strong>{{data.banco}}</strong></ion-text>
        </div>
      </ion-item>
      <ion-item *ngIf="data">
        <div>
          <ion-text color="primary">Monto:</ion-text>
          <br>
          <ion-text color="dark"><strong>{{data.monto}} Bs</strong></ion-text>
        </div>
      </ion-item>
    </ion-card-content>
  </ion-card>
  <ion-button icon-left size="medium" expand="full" shape="round" color="dark" class="ion-margin"
        tappable>
      Aceptar
    </ion-button>
</div>
</ion-content>



<ion-footer class="animated fadeIn">
  <ion-toolbar color="primary">
    <ion-grid class="ion-no-padding">
      <ion-row style="display: flex;justify-content: center;">
        <ion-col size="4" class="ion-no-padding">
          <ion-button
            size="small"
            shape="round"
            expand="full"
            fill="clear"
            color="tertiary"
            routerLink="/home"
          >
            <ion-icon slot="start" name="wallet"></ion-icon>
            <span class="icon-desc">
              {{ 'app.pages.menu.rentacar' | translate }}
            </span>
          </ion-button>
        </ion-col>
        <!-- <ion-col size="4" class="ion-no-padding">
          <ion-button
            size="small"
            shape="round"
            expand="full"
            fill="clear"
            color="tertiary"
            routerLink="/favorites"
          >
            <ion-icon slot="start" name="Cash"></ion-icon>
            {{ 'app.pages.menu.activitiestab' | translate }}
          </ion-button>
        </ion-col> -->
        <ion-col size="4" class="ion-no-padding">
          <ion-button
            size="small"
            shape="round"
            expand="full"
            fill="clear"
            color="tertiary"
            routerLink="/edit-profile"
          >
          <ion-icon slot="start" name="person-circle"></ion-icon>
            <span class="icon-desc">
              Perfil
            </span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
