<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>Reportar avería</ion-title>
    <!-- <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons> -->
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- 
   
--> <iframe *ngIf="secureUrl" [src]="secureUrl | url" style="
      width:100%;
      height: 99%;
    "></iframe>
</ion-content>