import * as i0 from "@angular/core";
export class LocalStorageService {
    constructor() { }
    set(item, data) {
        localStorage.setItem(item, JSON.stringify(data));
    }
    get(item) {
        return JSON.parse(localStorage.getItem(item));
    }
    remove(item) {
        localStorage.removeItem(item);
    }
    removeAll() {
        const id = JSON.parse(localStorage.getItem('Unique_IdDevice'));
        localStorage.clear();
        localStorage.setItem('Unique_IdDevice', JSON.stringify(id));
    }
}
LocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalStorageService_Factory() { return new LocalStorageService(); }, token: LocalStorageService, providedIn: "root" });
