/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./general-information.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@ionic/angular";
import * as i5 from "./general-information.page";
var styles_GeneralInformationPage = [i0.styles];
var RenderType_GeneralInformationPage = i1.ɵcrt({ encapsulation: 0, styles: styles_GeneralInformationPage, data: {} });
export { RenderType_GeneralInformationPage as RenderType_GeneralInformationPage };
export function View_GeneralInformationPage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ion-content", [["class", "bg_image"]], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "bg-mobile": 0, "bg-ipad": 1, "bg-desktop": 2 }), i1.ɵdid(4, 49152, null, 0, i4.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "div", [["class", "close-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "ion-icon", [["color", "tertiary"], ["name", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(7, 49152, null, 0, i4.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], name: [1, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bg_image"; var currVal_1 = _ck(_v, 3, 0, _co.isLittleMobile, (_co.isTablet || _co.isBigMobile), _co.isDesktop); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "tertiary"; var currVal_3 = "close"; _ck(_v, 7, 0, currVal_2, currVal_3); }, null); }
export function View_GeneralInformationPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-general-information", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_GeneralInformationPage_0, RenderType_GeneralInformationPage)), i1.ɵdid(1, 114688, null, 0, i5.GeneralInformationPage, [i4.ModalController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GeneralInformationPageNgFactory = i1.ɵccf("app-general-information", i5.GeneralInformationPage, View_GeneralInformationPage_Host_0, {}, {}, []);
export { GeneralInformationPageNgFactory as GeneralInformationPageNgFactory };
