import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubscriptionGraphqlService } from '../../../services/subscription-graphql.service';
import { ConsultasService } from '../../../services/consultas.service';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.page.html',
  styleUrls: ['./notification-modal.page.scss'],
})
export class NotificationModalPage implements OnInit {
  @Input() data: any;

  HideBtnClose: boolean

  constructor(
    private modalCtrl: ModalController,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _Consultas: ConsultasService,
    private _localStorageService: LocalStorageService
  ) {
    this._SubscriptionGraphqlService.OpSelect.subscribe((op: any) => {
      if (op.option === "op0-8") {
        this.closeModal();
      }
    })

  }

  ngOnInit() {
    if (this.data) {
      const DataFilter = this.data.find((D: any) => D.ShowBoton === true)
      if (DataFilter) {
        this.HideBtnClose = DataFilter.ShowBoton
      } else { this.HideBtnClose = false }
    }
  }

  RegisterViewCobranza(Datos: any) {
    try {

      if (Datos) {
        const DataC = {
          "Cedula": Datos.cedula,
          "Abonado": Datos.NroAbonado,
          "Msg": Datos.mensaje
        }

        this._Consultas.InsertViewControlCobranza(DataC).then((Res: any) => {
          this.closeModal()
          this._localStorageService.set('ViewMsgCobranza', { status: true, Fecha: new Date() })
        }).catch((error: any) => {
          console.error(error)
        })

      }

    } catch (error) {
      console.error(error)
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
