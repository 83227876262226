import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { LogErrorsService } from './log-errors.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./log-errors.service";
export class ConsultaPagoMovilService {
    constructor(_http, _logs) {
        this._http = _http;
        this._logs = _logs;
    }
    consultaBancos() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this._http.get(`${environment.apiDbMaps}find-all-info/tmBancos`).toPromise();
                return {
                    error: false,
                    data: response,
                };
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    error: true,
                    data: []
                };
            }
        });
    }
}
ConsultaPagoMovilService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConsultaPagoMovilService_Factory() { return new ConsultaPagoMovilService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LogErrorsService)); }, token: ConsultaPagoMovilService, providedIn: "root" });
