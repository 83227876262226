<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Notificación</ion-title>
    <ion-buttons slot="end" *ngIf="!HideBtnClose">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding animated fadeIn fast">
  <ion-card class="bg-white" *ngFor="let Msg of data">
    <ion-card-content class="ion-padding">
      <ion-text>
        {{Msg.mensaje}}
      </ion-text>
    </ion-card-content>
    <ion-text style="margin-left: inherit;">{{Msg.Fecha | date:'dd/MM/yyyy H:mm'}}</ion-text>
    <ion-button icon-left shape="round" size="full" class="custom" *ngIf="Msg.ShowBoton"
      (click)="RegisterViewCobranza(Msg)">
      <ion-ripple-effect></ion-ripple-effect>
      Aceptar
    </ion-button>
  </ion-card>
</ion-content>