import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ProductInterface } from '../../../../interfaces/product.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list1',
  templateUrl: './list1.component.html',
  styleUrls: ['./list1.component.scss'],
})
export class List1Component implements OnInit {

  @Input() item: ProductInterface;
  @Input() category: string = 'green';

  constructor(
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  goToRestDetail(event) {
    const target = event.target as Element;
    
    this.router.navigate(['club-fibex/product-details', this.item.id, ((target.id).includes('btn_show_qr'))])
  }

  public subtractToNumber(num1: unknown, num2: unknown): number {
    return Number(num1) - Number(num2);
  }

}
