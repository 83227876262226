import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Hls from 'hls.js';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { ConsultasService } from '../../../services/consultas.service';
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";
import { GuiaProgramacionService } from '../../../services/guia-programacion.service';
import { GetIdDeviceService } from '../../../services/get-id-device.service';
import { EstadisticasService } from '../../../services/estadisticas.service';
import { LogErrorsService } from '../../../services/log-errors.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { AlertController } from '@ionic/angular';
export class VideoPlayerPage {
    constructor(modal, consultService, userAuthService, _SubscriptionGraphqlService, guia, _IdDevice, _estadisticas, _logs, _localstorage, alert) {
        this.modal = modal;
        this.consultService = consultService;
        this.userAuthService = userAuthService;
        this._SubscriptionGraphqlService = _SubscriptionGraphqlService;
        this.guia = guia;
        this._IdDevice = _IdDevice;
        this._estadisticas = _estadisticas;
        this._logs = _logs;
        this._localstorage = _localstorage;
        this.alert = alert;
        this.mute = false;
        this.play = true;
        this.video = null;
        this.error = false;
        this.dayPointer = '';
        this._estadisticas.RegisterAccion("11");
        this.user = this.userAuthService.getUser();
        this.ClientActual = this._localstorage.get('cedulaCliente');
        //
        this.consultService.GetIpDevice().then((ResIP) => {
            this.ipUser = ResIP;
        });
        this._IdDevice.getUniqueDeviceID().then((ResId) => {
            this.IDDevice = ResId;
        });
        this.UniqueIdDevice = this._localstorage.get('Unique_IdDevice');
    }
    ngOnInit() {
        // this.socketService.socketConnection('ws://192.168.227.8:8081');
        //this.guia.guideState.subscribe((data) => {
        // this.getGuia(data);
        //})
        // this.getGuia();
        // this.init();
        this.CheckDevice();
    }
    ngOnDestroy() {
        this.hls.destroy();
        clearInterval(this.interval);
    }
    CheckDevice() {
        const DataTv = {
            idDevice: this.IDDevice || this.UniqueIdDevice,
            Documento: this.ClientActual ? this.ClientActual.identidad : this.user.identidad,
            Canal: this.data.channel_title,
            Plataforma: "android",
            IP: this.ipUser || ''
        };
        this.consultService.ControlPermisoTv(DataTv).then((ResTv) => {
            if (ResTv.Permiso) {
                this.getGuia();
                this.init();
                this.consultService.SolicitudPermisoTv(DataTv);
            }
            else {
                this.closeModal();
                this.createAlert();
            }
        }).catch(error => {
            console.error(error);
            this._logs.insertarError(error.message);
        });
    }
    init() {
        let video = document.getElementById('video-hls');
        video.requestFullscreen({
            navigationUI: 'auto'
        });
        if (Hls.isSupported()) {
            this.hls = new Hls();
            this.title = this.data.channel_title;
            this.hls.loadSource(this.data.source);
            this.hls.attachMedia(video);
            this.hls.on(Hls.Events.MEDIA_ATTACHED, function () {
                video.muted = false;
                video.play();
            });
            this.timerUserWatchingStreaming();
        }
        else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
            video.addEventListener('canplay', function () {
                video.play();
            });
            this.timerUserWatchingStreaming();
        }
    }
    createAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alert.create({
                header: 'Ha alcanzado la cantidad máxima de dispositivos conectado',
                cssClass: 'secondary cust',
                buttons: [
                    {
                        text: 'Cerrar',
                        role: 'Cancelar',
                        cssClass: 'secondary',
                    },
                ]
            });
            alert.present();
        });
    }
    getGuia() {
        try {
            const dataGuide = this.guia.getData();
            const guide = !!this.data.guide_id ? dataGuide.tv.programme[this.data.guide_id] : null;
            if (!!guide) {
                const dateObj = new Date();
                const month = dateObj.getMonth() + 1; //months from 1-12
                const day = dateObj.getDate();
                const year = dateObj.getFullYear();
                const date = `${month}/${day}/${year}`;
                this.daysArr = Object.keys(guide.programmeItems);
                this.dayPointer = guide.programmeItems[date] ? date : this.daysArr[this.daysArr.length - 1];
            }
            this.channelGuide = guide;
            if (guide) {
                setTimeout(() => {
                    this.setCarrousellPosition();
                }, 300);
            }
        }
        catch (error) {
            this._logs.insertarError(error.message);
            this.channelGuide = null;
        }
    }
    selectDay(day) {
        if (this.dayPointer !== day) {
            this.dayPointer = day;
            this.setCarrousellPosition();
            const target = document.querySelector('.grid-items-container');
            target.scrollTop = 0;
        }
    }
    setCarrousellPosition() {
        const container = document.querySelector('.days-container');
        const position = this.daysArr.indexOf(this.dayPointer);
        const target = container.children[position];
        if (target) {
            if (position >= 0 || position < this.daysArr.length) {
                target.parentElement.parentElement.scrollLeft = target.offsetLeft + target.clientLeft - 56;
            }
        }
    }
    handleNextPosition() {
        const currentPosition = this.daysArr.indexOf(this.dayPointer);
        if (currentPosition <= this.daysArr.length - 2) {
            this.dayPointer = this.daysArr[currentPosition + 1];
            this.setCarrousellPosition();
        }
    }
    hanldePrevPosition() {
        const currentPosition = this.daysArr.indexOf(this.dayPointer);
        if (currentPosition >= 1) {
            this.dayPointer = this.daysArr[currentPosition - 1];
            this.setCarrousellPosition();
        }
    }
    hanldeMute() {
        if (this.mute) {
            this.mute = false;
        }
        else {
            this.mute = true;
        }
        this.video.muted = this.mute;
    }
    hanldePlay() {
        if (this.video.paused || this.video.ended) {
            this.play = true;
            this.video.play();
        }
        else {
            this.play = false;
            this.video.pause();
        }
    }
    closeModal() {
        this.modal.dismiss();
        if (!navigator.userAgent.toLocaleLowerCase().includes('android' || 'iphone' || 'ipad' || 'webos')) {
            this.hls.destroy();
        }
        clearInterval(this.interval);
        this.CleanDevice();
    }
    CleanDevice() {
        this.consultService.DeleteDevice({
            idDevice: this.IDDevice || this.UniqueIdDevice,
            Documento: this.ClientActual ? this.ClientActual.identidad : this.user.identidad,
            Canal: this.data.channel_title,
            Plataforma: "android",
            IP: this.ipUser || ''
        }).then((ResDelete) => {
            //proceso terminado
        });
    }
    fancyTimeFormat(duration) {
        // Hours, minutes and seconds
        let hrs = Math.floor(duration / 3600);
        let mins = Math.floor((duration % 3600) / 60);
        let secs = Math.floor(duration % 60);
        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";
        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }
        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }
    formatDate(dateString) {
        const date = new Date(dateString);
        const options = { weekday: 'long', month: 'long', day: 'numeric' };
        const string = Intl.DateTimeFormat('es-ES', options).format(date);
        return `${string}`.replace('de', '-');
    }
    formatMinutesHours(dateString) {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    }
    timerUserWatchingStreaming() {
        this.interval = setInterval(() => {
            this.consultService.SolicitudPermisoTv({
                idDevice: this.IDDevice || this.UniqueIdDevice,
                Documento: this.ClientActual.identidad || this.user.identidad,
                Canal: this.data.channel_title,
                Plataforma: "android",
                IP: this.ipUser || ''
            }).then((res) => {
                //resultado enviado
            });
        }, 300000);
    }
}
