import * as tslib_1 from "tslib";
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { AlertController } from '@ionic/angular';
import { LogErrorsService } from './log-errors.service';
import { LocalStorageService } from './local-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic-native/unique-device-id/ngx/index";
import * as i2 from "@ionic/angular";
import * as i3 from "./log-errors.service";
import * as i4 from "./local-storage.service";
export class GetIdDeviceService {
    constructor(uniqueDeviceID, alertController, _logs, localStorageService) {
        this.uniqueDeviceID = uniqueDeviceID;
        this.alertController = alertController;
        this._logs = _logs;
        this.localStorageService = localStorageService;
    }
    getUniqueDeviceID() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // navigator.mediaDevices.enumerateDevices().then(devices => {
            //   console.log(devices);
            // });
            // return new Promise(async (resolve: any, reject: any) => {
            //   try {
            //     const id = await this.uniqueDeviceID.get();
            //     resolve(id)
            //   } catch (error) {
            //     this._logs.insertarError(error.message);
            //     console.error(error);
            //   }
            // })
            // El modulo que se esta usando para los id unicos no va a funcionar jamas en la vida
            // porque 1. Es muy viejo y 2. No se puede usar en modo navegador.
            return this.localStorageService.get('Unique_IdDevice');
        });
    }
}
GetIdDeviceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetIdDeviceService_Factory() { return new GetIdDeviceService(i0.ɵɵinject(i1.UniqueDeviceID), i0.ɵɵinject(i2.AlertController), i0.ɵɵinject(i3.LogErrorsService), i0.ɵɵinject(i4.LocalStorageService)); }, token: GetIdDeviceService, providedIn: "root" });
