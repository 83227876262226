<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Servicios</ion-title>
    <ion-buttons slot="start" *ngIf="!useModal">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-buttons slot="end" *ngIf="useModal">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding animated fadeIn fast" [scrollEvents]="true" (ionScroll)="handleShowScrollArrow($event)">

 <div class="booking-card">
    <ion-card class="searchcard animated fadeIn" *ngFor="let equipo of infoEquipo;">
      <ion-card-content class="ion-no-padding">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col class="ion-no-padding">
              <form [formGroup]="formService" class="list-form">
                <ion-card class="ion-no-margin ion-margin-bottom bg-white">
                  <ion-item>
                    <ion-label class="service-type">
                      <ion-text color="primary" style="margin-right: 10px;"><strong>SUSCRIPTOR:</strong></ion-text>
                      <ion-text color="primary"><strong>{{equipo.nro_contrato}}</strong></ion-text>
                    </ion-label>
                  </ion-item>
                </ion-card>
              </form>
            </ion-col>
          </ion-row>
         
        </ion-grid>
      </ion-card-content>
        <ion-card-content style="padding-top: 0px;">
      
          <ion-row class="adjust-qts" *ngFor="let serv of equipo.Servicios">
            <ion-col size="12" class="ion-no-padding ion-margin-top ion-margin-bottom bb bt br bl">

              <ion-item>
                <ion-label class="ion-no-padding ion-no-margin text-primary text08">
                  <ion-text color="dark"> <ion-text color="dark"><strong>Tipo de servicio:</strong></ion-text></ion-text>
                </ion-label>
                <ion-text color="dark">{{ serv.nombre_servicio | dictionary}}</ion-text>
              </ion-item>

              <ion-item>
                <ion-label class="ion-no-padding ion-no-margin text-primary text08">
                  <ion-text color="dark"> <ion-text color="dark"><strong>Plan:</strong></ion-text></ion-text>
                </ion-label>
                <ion-text color="dark">{{ filterPackages(serv.nombre_servicio) | number }}</ion-text>
              </ion-item>

              <ion-item>
                <ion-label class="ion-no-padding ion-no-margin text-primary text08">
                  <ion-text color="dark"> <ion-text color="dark"><strong>Estatus:</strong></ion-text></ion-text>
                </ion-label>
                <ion-text color="dark">{{serv.status_serv | titlecase}}</ion-text>
              </ion-item>

              

            </ion-col>   
            <ion-col class="ion-no-padding ion-no-margin ion-padding-start" *ngIf="!infoEquipo.length">
              <ion-item>
                <ion-text color="dark" ><strong>No tiene servicios disponibles.</strong></ion-text>
              </ion-item>
            </ion-col>
          </ion-row>

        </ion-card-content>
    </ion-card>

    <div class="btns_container" *ngIf="service">
      <ion-button class="fancy_btn animated fadeIn" mode="ios" (click)="navigateTo('/planes')"> Subir de plan <ion-icon slot="start" name="cart"></ion-icon></ion-button>
      <ion-button class="fancy_btn animated fadeIn" mode="ios" (click)="navigateTo('/mi-equipo')"> Mi equipo <ion-icon slot="start" name="wifi"></ion-icon></ion-button>
    </div>

  </div> 
  <div class="spinner_container">
    <ion-spinner *ngIf="!infoEquipo" color="primary"></ion-spinner>
  </div>

  <button class="arrow_up animated faster" (click)="scrollToTop()" [class.fadeInUp]="showScrollArrow"
    [class.fadeOutDown]="!showScrollArrow">
    <ion-icon name="chevron-up-outline"></ion-icon>
  </button>

</ion-content>