import { Injectable } from '@angular/core';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { AlertController } from '@ionic/angular';
import { LogErrorsService } from './log-errors.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class GetIdDeviceService {

  constructor(
    private uniqueDeviceID: UniqueDeviceID,
    public alertController: AlertController,
    private _logs: LogErrorsService,
    private localStorageService: LocalStorageService,
  ) { }

  async getUniqueDeviceID() {
    // navigator.mediaDevices.enumerateDevices().then(devices => {
    //   console.log(devices);
    // });
    // return new Promise(async (resolve: any, reject: any) => {
    //   try {
    //     const id = await this.uniqueDeviceID.get();
    //     resolve(id)
    //   } catch (error) {
    //     this._logs.insertarError(error.message);
    //     console.error(error);
    //   }
    // })
    // El modulo que se esta usando para los id unicos no va a funcionar jamas en la vida
    // porque 1. Es muy viejo y 2. No se puede usar en modo navegador.
    return this.localStorageService.get('Unique_IdDevice');
  }
}
