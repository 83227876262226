<!-- <ion-header>
  <ion-toolbar>
    <ion-title>Referido</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="close()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header> -->

<ion-content class="bg_image" [ngClass]="{'bg-mobile': isLittleMobile,'bg-ipad': isTablet || isBigMobile, 'bg-desktop': isDesktop }">
  <!-- <ion-buttons slot="end">
    <ion-button size="small" shape="round" (click)="close()" color="tertiary">
      <ion-icon slot="start" name="close"></ion-icon>
    </ion-button>
  </ion-buttons> -->
  
  <div class="close-btn">
      <ion-icon name="close" (click)="close()" color="tertiary"></ion-icon>
  </div>
  <!-- <ion-list>

    <ion-item >

      <ion-label text-wrap> ● Refiere a tus familiares o amigos y disfruta de increíbles beneficios</ion-label>

    </ion-item>

    <ion-item>

      <ion-label text-wrap> ● Para reclamar el beneficio el servicio de tu referido debe estar instalado</ion-label>

    </ion-item>

    <ion-item>

      <ion-label text-wrap> ● Si la instalación de tu referido está activa,  click aquí para solicitar el beneficio</ion-label>

    </ion-item>

  </ion-list> -->

</ion-content>
