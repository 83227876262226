import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { SubscriptionGraphqlService } from "../../services/subscription-graphql.service";
import { SuportModalPage } from '../modal/suport-modal/suport-modal.page';
import { ConsultasService } from '../../services/consultas.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { VerifyInternetStatusService } from '../../services/verify-internet-status.service';
import { EstadisticasService } from '../../services/estadisticas.service';
import { LogErrorsService } from '../../services/log-errors.service';
import { RoutingAppService } from '../../services/routing-app.service';
const moment = require('moment/moment');
export class SupportPage {
    constructor(_routing, sanatizer, _SubscriptionGraphqlService, modalCtrl, consulta, _localStorageService, _internetService, _estadisticas, _logs) {
        this._routing = _routing;
        this.sanatizer = sanatizer;
        this._SubscriptionGraphqlService = _SubscriptionGraphqlService;
        this.modalCtrl = modalCtrl;
        this.consulta = consulta;
        this._localStorageService = _localStorageService;
        this._internetService = _internetService;
        this._estadisticas = _estadisticas;
        this._logs = _logs;
        this.onLine = true;
        this.tableSoport = [];
        this.counter = 0;
        this.showScrollArrow = false;
        this.FechaActual = moment();
        this._internetService.testNetworkConnection();
        this.onLine = this._internetService.onLine;
        this._internetService.internetState.subscribe((data) => {
            this.onLine = data;
            if (!this.onLine) {
                this._internetService.verifyConection(this.onLine, true);
                this._routing.ChangeRuta('home');
            }
            // this._internetService.verifyConection(this.onLine||data, true);
        });
    }
    ngOnInit() {
        this.getSoporteData();
        this._SubscriptionGraphqlService.OpSelect.subscribe((op) => {
            switch (op.option) {
                case "op3-1":
                    this.openSuportModal(this.tableSoport[0]);
                    break;
                case "op3-2":
                    this.openReporteModal();
                    break;
            }
        });
    }
    getSoporteData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const idContrato = this._localStorageService.get('CedulaActual').id_contrato;
                const data = yield this.consulta.GetHistorialOrdenes(idContrato);
                for (let indexData = 0; indexData < data.length; indexData++) {
                    if (this.CalcularMeses(data[indexData].fecha_orden) < 6) {
                        this.tableSoport.push(data[indexData]);
                    }
                }
                //this.tableSoport = data;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                this.tableSoport = [];
            }
        });
    }
    CalcularMeses(FRegistro) {
        var FechaRegistro = moment(FRegistro);
        return this.FechaActual.diff(FechaRegistro, 'months');
    }
    openSuportModal(Detal) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!Detal) {
                return;
            }
            const modal = yield this.modalCtrl.create({
                component: SuportModalPage,
                componentProps: { data: Detal }
            });
            yield modal.present();
        });
    }
    editprofile() {
        this._routing.ChangeRuta('edit-profile');
    }
    MostrarDetal(Detal) {
        this.openSuportModal(Detal);
    }
    openReporteModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //reporte-averia-modal
            this._routing.ChangeRuta('reporte-averia-modal');
            /* if (!this.onLine) {
              this._internetService.displayAlert();
              return;
            }
            const modal = await this.modalCtrl.create({
              component: ReporteAveriaModalPage,
            });
            await modal.present(); */
        });
    }
    scrollToTop() {
        this.content.scrollToTop(600); // goes to the top instead of instantly
    }
    handleShowScrollArrow(ev) {
        this.showScrollArrow = ev.detail.scrollTop > 0 ? true : false;
    }
}
