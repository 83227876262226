import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { IonSlides, NavController } from '@ionic/angular';
export class Card1Component {
    constructor(ClubFibexServ, navCtrl) {
        this.ClubFibexServ = ClubFibexServ;
        this.navCtrl = navCtrl;
        this.currentCategory = '';
        this.categories = [
            { name: 'Inicio', class: 'home' },
            { name: 'Gastronomía', class: 'gastronomy' },
            { name: 'Tecnología', class: 'technology' },
            { name: 'Salud y Bienestar', class: 'health' },
            { name: 'Educación y Deportes', class: 'sport' },
            { name: 'Turismo', class: 'turism' },
            { name: 'Retail', class: 'retail' },
            { name: 'Entretenimiento', class: 'entertainment' },
        ];
        this.slideBannerOptions = {
            // autoplay: true,
            autoplay: false
        };
    }
    ngOnInit() {
        const index = this.categories.findIndex(eCategory => eCategory.name === this.item.Categoria);
        this.currentCategory = this.categories[index].class;
    }
    addFavoriteItem(item) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.ClubFibexServ.handleProductFav(item);
        });
    }
    goToRestDetail() {
        // this.router.navigate(['club-fibex/product-details', this.item.id, false])
        this.navCtrl.navigateRoot(['club-fibex/product-details', this.item.id, false]);
    }
    parseString(image) {
        if (Array.isArray(image))
            return image;
        if (typeof image === "string")
            return JSON.parse(image);
        return null;
    }
    slideNext() {
        if (this.slides)
            this.slides.slideNext();
    }
    slidePrev() {
        if (this.slides)
            this.slides.slidePrev();
    }
}
