import { diccionario } from '../providers/diccionario';
import * as i0 from "@angular/core";
export class DiccionarioService {
    constructor() {
        this.diccionario = diccionario;
    }
    reemplazarServicio(servicio) {
        const names = this.diccionario.map((item) => item.name);
        const index = names.indexOf(servicio.trim());
        if (index >= 0) {
            return this.diccionario[index].velocidadMB;
        }
        return servicio;
    }
}
DiccionarioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DiccionarioService_Factory() { return new DiccionarioService(); }, token: DiccionarioService, providedIn: "root" });
