<ion-header>
  <ion-toolbar color="primary" mode="ios">
    <ion-title>LLÁMAME</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-card mode="ios">

    <ion-card-content mode="ios">
      <ion-item style="margin-top: 5%; margin-bottom: 5%; color: #000000;" mode="ios">
        <!-- <ion-text>Por favor ingrese su número de teléfono al cual quiere que lo contactemos</ion-text> -->
        <ion-text>Por favor ingrese el número de teléfono al cuál lo contactaremos</ion-text>
      </ion-item>
    </ion-card-content>

    <ion-grid fixed>
      <!-- style="background-color: white;" -->
      <ion-row>
        <ion-col>

          <form [formGroup]="CallForm" class="list-form">

            <ion-item class="ion-no-padding animated fadeInUp" mode="ios">
              <ion-label position="floating">
                <ion-icon name="call" item-start></ion-icon>
                <span style="color: #000000; margin-left: 1%;">Teléfono:</span>
              </ion-label>
              <ion-input type="number" formControlName="Phone" mode="ios" style="color: #000000;"></ion-input>
            </ion-item>

            <div class="ion-margin-top">
              <ion-button mode="ios" icon-left size="medium" expand="full" shape="round" class="default" (click)="Call()"
                [disabled]="!CallForm.valid" tappable>
                Llámame
              </ion-button>
              <!-- <ion-button icon-left size="medium" expand="full" shape="round" color="primary" (click)="CancelProcess()"
                tappable>
                Cancelar
              </ion-button> -->
            </div>

          </form>

        </ion-col>
      </ion-row>
    </ion-grid>

  </ion-card>

</ion-content>