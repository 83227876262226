import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class WeatherService {
    constructor(http) {
        this.http = http;
        this.apiKey = '3846169fbe28c1f5498aa431a3cb010e'; // Please generate your own api key here --> https://openweathermap.org
        this.url = 'https://api.openweathermap.org/data/2.5/weather?q=';
    }
    getWeather(state, city) {
        return this.http.get(this.url + city + ',' + state + '&appid=' + this.apiKey).pipe(map(this.extractData), catchError(this.handleError));
    }
    // Private
    extractData(res) {
        let body = res;
        return body || {};
    }
    handleError(error) {
        let errMsg;
        if (error instanceof Response) {
            const err = error || '';
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else {
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return Observable.throw(errMsg);
    }
}
WeatherService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WeatherService_Factory() { return new WeatherService(i0.ɵɵinject(i1.HttpClient)); }, token: WeatherService, providedIn: "root" });
