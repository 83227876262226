import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal1',
  templateUrl: './modal1.component.html',
  styleUrls: ['./modal1.component.scss'],
})
export class Modal1Component implements OnInit {

  @Output() successBtnEmit: EventEmitter<void> = new EventEmitter<void>(null);
  @Input() cancelTextBtn: string = '';
  @Input() successTextBtn: string = '';
  @Input() title: string = '';
  @Input() modalImage: string = '';

  constructor() { }

  ngOnInit() {}

  successEvent() {
    this.successBtnEmit.emit()
  }

}
