import { Component, OnInit } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { ConsultasService } from '../../services/consultas.service';

@Component({
  selector: 'app-new-update',
  templateUrl: './new-update.page.html',
  styleUrls: ['./new-update.page.scss'],
})
export class NewUpdatePage implements OnInit {
  Url: any
  Mensaje = 'Hemos encontrado una nueva actualización disponible, por favor actualizar a la versión más reciente para que pueda seguir disfrutando de Fibex Oficina Móvil'

  constructor(
    private ConsultasS: ConsultasService,
    public menuCtrl: MenuController,
  ) { }

  ngOnInit() {
    this.Url = this.ConsultasS.UrlUpdate
    this.menuCtrl.enable(false);
  }

  GoToUpdate() {
    window.open(this.Url);
  }



}
