import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PinMasterPageRoutingModule } from './pin-master-routing.module';

import { PinMasterPage } from './pin-master.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    PinMasterPageRoutingModule
  ],
  declarations: [PinMasterPage]
})
export class PinMasterPageModule { }
