import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RoutingAppService } from '../../../services/routing-app.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, OnDestroy {

  @Input() showSpinner: boolean = false;

  constructor(
  ) {
  }

  ngOnInit() {}

  ngOnDestroy(): void {
  }

}
