import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ConsultasService } from '../../../services/consultas.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-pin-master',
  templateUrl: './pin-master.page.html',
  styleUrls: ['./pin-master.page.scss'],
})
export class PinMasterPage implements OnInit {
  @Input() Header: any;
  @Input() SubHeader: any;
  @Input() Content: any;

  Data: any
  PinGenerator: any
  PinForm: FormGroup;
  PinError: Boolean = false

  constructor(
    public formBuilder: FormBuilder,
    private _localStorageService: LocalStorageService,
    private consultasS: ConsultasService,
    private modalCtrl: ModalController,
  ) {
    this.Data = this._localStorageService.get('DataPinMaster')
    this.PinForm = this.formBuilder.group({
      Pin: ['', [Validators.required, Validators.minLength(6)]]
    })

    this.GenerateEmail()
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss({ status: false });
  }

  GenerateEmail() {
    this.PinGenerator = Math.random().toString(16).slice(3, 9)
    if (this.Data && this.Data.email) {
      let ContentEmail = `¡Hola! <br> El siguiente código <strong>${this.PinGenerator}</strong> es para completar su registro en <strong>Fibex Oficina Móvil</strong>`
      this.consultasS.sendMail(this.Data.email, 'Pin de confirmación de cuenta', ContentEmail).then((ResEmail: any) => {
        //email  enviado
      }).catch(error => console.error())
    }
  }

  VerifyPin() {
    if (this.PinForm.value) {
      let pin = this.PinForm.get('Pin').value

      if (pin === this.PinGenerator) {
        this.modalCtrl.dismiss({ status: true })
        this._localStorageService.remove('DataPinMaster')

      } else {
        this.PinError = true

        setTimeout(() => {
          this.PinError = false
        }, 2000);

      }

    }

  }

}
