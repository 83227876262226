<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{data.title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon> 
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="animated fadeIn">
  <div class="auth-content" id="modaltype">

    <form [formGroup]="pinValidationForm" class="list-form">
      <ion-item  class="ion-no-padding animated fadeInUp" >
        <ion-label position="floating" color="light">
          Introduzca Pin:
        </ion-label>
        <ion-input color="secondary" type="text" formControlName="pinValue" color="light"></ion-input>
      </ion-item>   
      <div class="ion-margin-top">
        <ion-button icon-left size="medium" expand="full" shape="round" color="dark" (click)="getPin()"
          tappable [disabled]="!pinValidationForm.valid">
          Verificar
        </ion-button>
      </div>
    </form>
  </div>
</ion-content>