import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';
import { RoutingAppService } from '../routing-app.service';

@Injectable({
  providedIn: 'root'
})
export class KioskoGuard implements CanActivate {

  constructor(
    private router: Router,
    private _routing: RoutingAppService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isKioskoApp = environment.kioskoApp;
    if (isKioskoApp) {
      this._routing.ChangeRuta('/new-login');
      return false;
    }
    return true;
  }

}
