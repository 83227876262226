<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{ title || 'Canales'}}</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding animated fadeIn fast">
  <div class="main_content_div">

    <div class="rest_div">

      <div class="rest_detail">
        <div class="name_flex">
          <div class="video-container">
            <video id="video-hls" controls>
            </video>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-container" *ngIf="channelGuide">
      <div class="grid-days-container">
        <div class="days-container">
          <div class="days-item" *ngFor="let item of daysArr" [ngClass]='dayPointer === item ? "days-item-active": ""' (click)="selectDay(item)">
              {{formatDate(item)}}
          </div>
        </div>
      </div>
      <div class="prev-btn" (click)="hanldePrevPosition()">
        <ion-icon size="large" name="arrow-round-back"></ion-icon>
      </div>
      <div class="next-btn" (click)="handleNextPosition()">
        <ion-icon size="large" name="arrow-round-forward"></ion-icon>
      </div>
      <div class="grid-items-container">
        <div class="items-container">
          <div class="grid-item" *ngFor="let item of channelGuide.programmeItems[dayPointer].programmeItemsByDay">
            <div class="grid-hour">
              <p>
                {{formatMinutesHours(item._attributes.start)}} - {{formatMinutesHours(item._attributes.stop)}}
              </p>
            </div>
            <div class="grid-tittle">
              <p>
                {{item.title._text}}
              </p>
            </div>            
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!channelGuide" style="padding: 30px;">
      No hay una guia disponible para este canal.
    </div>
  </div>
</ion-content>