import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CallMePageRoutingModule } from './call-me-routing.module';

import { CallMePage } from './call-me.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    CallMePageRoutingModule
  ],
  declarations: [CallMePage]
})
export class CallMePageModule {}
