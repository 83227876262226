import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSelect } from '@ionic/angular';
import { CouponProductInterface, ProductInterface } from '../../../../interfaces/product.interface';

@Component({
  selector: 'app-list2',
  templateUrl: './list2.component.html',
  styleUrls: ['./list2.component.scss'],
})
export class List2Component implements OnInit {
  // @ViewChild(`${this.item.id}`, {static: true}) itemElement: Element;
  @ViewChild('selector', { static: false }) selector: IonSelect
  @Input() item: CouponProductInterface;
  @Input() tabID: string = 'withoutUsing';
  @Input() coupons: CouponProductInterface[] = [];
  @Output() cuponCanjeado: EventEmitter<CouponProductInterface> = new EventEmitter();
  public showCouponSelector: boolean = false;
  public totalCoupons: CouponProductInterface[] = [];
  public showModal: boolean = false;
  public currentItem: CouponProductInterface;
  public totalMissingDays: number = 0;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.CalcMissingDays()
    this.totalCoupons = this.coupons.filter(eItem => eItem.id === this.item.id)
    this.currentItem = this.item;
    console.log('this.totalCoupons', this.totalCoupons)
  }

  itemAccordion(itemId: string) {//Codigo para el acordion de cupones
    if (this.tabID === "withoutUsing") {

      const allInputs: any = document.querySelectorAll('.check_input_accordion');
      const divContent: any = document.querySelectorAll('.div_content');
      allInputs.forEach((input: any, i) => {//Cierra el contenido de los demas cupones
        if (input.id != itemId) { input.checked = false; divContent[i].classList.remove('acordion_active') }
      })

      const inputCheck: any = document.getElementById(`${itemId}`);
      const content = document.getElementById(`div_content_${itemId}`);
      if (inputCheck.checked) content.classList.add('acordion_active') //Abre el contenido del cupon seleccionado
      else content.classList.remove('acordion_active')
    }
  }


  goToRestDetail(id: string, showCoupon: boolean = false) {
    // this.currentItem = this.totalCoupons[0]
    console.log(this.currentItem)
    // this.showModal = true;
    this.router.navigate(['club-fibex/product-details', id, showCoupon])
  }

  isContentActive(itemId: string): boolean {
    const inputCheck: any = document.getElementById(`${itemId}`);
    return (inputCheck && inputCheck.checked ? inputCheck && inputCheck.checked : false)
  }

  public itemSelected(): void {
    // this.currentItem = this.totalCoupons[+e.target.value]
    console.log('this.currentItem', this.currentItem)
    if (this.totalCoupons && this.totalCoupons.length > 1) this.handleShowCouponSelector(true)
    else this.showModal = true
  }

  public handleShowCouponSelector(value: boolean): void {
    this.showCouponSelector = value;
  }

  openSelector() {
    this.selector.open()
  }

  private CalcMissingDays(): void {
    let FechaFinComparar = new Date(this.item.FechaFin).getTime();
    let FechaActual = new Date().getTime();
    let diff = FechaFinComparar - FechaActual;
    this.totalMissingDays = Math.round(diff / (1000 * 60 * 60 * 24))
    console.log('totalMissingDays', this.totalMissingDays)
  }

  public goToCuponSuccessed() {
    console.log("@CUPON_SUCCESSED")
    this.showModal = false;
    this.cuponCanjeado.emit(this.currentItem);
  }

  public selectCoupon(coupon: CouponProductInterface): void {
    this.currentItem = coupon;
    this.handleShowCouponSelector(false)
    this.showModal = true;
  }

  public subtractToNumber(number1: unknown, number2: unknown): number {
    return Number(number1) - Number(number2);
  }

}
