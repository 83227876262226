import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ProductInterface } from '../../../../interfaces/product.interface';
import { Router } from '@angular/router';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { IonSlides, NavController } from '@ionic/angular';

@Component({
  selector: 'app-card1', //ESTE ES EL TEMPLATE DEL CARD PARA LOS PRODUCTOS HORIZONTALES CON OPCION DE FAVORITOS
  templateUrl: './card1.component.html',
  styleUrls: ['./card1.component.scss'],
})
export class Card1Component implements OnInit {
  @ViewChild("slides", {static: false}) slides: IonSlides;
  @Input() heart: boolean;
  @Input() item: ProductInterface;

  currentCategory: string = '';
  categories: {name: string, class:string}[] = [
    {name:'Inicio', class:'home'},
    {name:'Gastronomía', class:'gastronomy'},
    {name:'Tecnología', class:'technology'},
    {name:'Salud y Bienestar', class:'health'},
    {name:'Educación y Deportes', class:'sport'},
    {name:'Turismo', class:'turism'},
    {name:'Retail', class:'retail'},
    {name:'Entretenimiento', class:'entertainment'},
    
  ];
  public slideBannerOptions = {
    // autoplay: true,
    autoplay: false
  }

  constructor(
    private ClubFibexServ: ClubFibexFeedingService,
    private navCtrl: NavController
  ) { }

  ngOnInit(): void { 
    const index = this.categories.findIndex(eCategory => eCategory.name === this.item.Categoria)
    this.currentCategory = this.categories[index].class
  }
  
  public async addFavoriteItem(item: ProductInterface): Promise<void> {
    await this.ClubFibexServ.handleProductFav(item)
  }

  public goToRestDetail(): void {
    // this.router.navigate(['club-fibex/product-details', this.item.id, false])
    this.navCtrl.navigateRoot(['club-fibex/product-details', this.item.id, false])
  }

  public parseString(image: string | string[] | unknown): unknown | string[] {
    if (Array.isArray(image)) return image
    if (typeof image === "string") return JSON.parse(image)
    return null
  }

  slideNext() {
    if (this.slides) this.slides.slideNext()
  }

  slidePrev() {
    if (this.slides) this.slides.slidePrev()
  }

}