import { Injectable, EventEmitter } from '@angular/core';
import { UserInfo, UserInterface } from '../interfaces/userInfo';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment.prod';
import { LocalStorageService } from './local-storage.service';
import { LogErrorsService } from './log-errors.service';
import { ConsultasService } from './consultas.service';
import { SeguridadDatos } from './bscript.service';

@Injectable({
  providedIn: 'root'
})

export class UserAuthenticationService {
  public userState: EventEmitter<any> = new EventEmitter<any>()
  constructor(
    public http: HttpClient,
    private _localStorageService: LocalStorageService,
    private _logs: LogErrorsService,
    private _consulta: ConsultasService,
    private security: SeguridadDatos,
  ) { }

  public getUser(): UserInterface | null {
    return this._localStorageService.get('user') || null;
  }

  public registerUser(userInfo: UserInfo) {
    this._localStorageService.removeAll();
    this._createAccount(userInfo);
    this.login({ email: userInfo.email, password: userInfo.password })
    this.userState.emit({ ...userInfo, municipio: "", estado: "", parroquia: "" });
    return true;
  }

  _createAccount(userInfo: UserInfo) {
    let user: any = userInfo
    const { identidad, nro_contrato } = user
    console.log(user)
    this._localStorageService.set('user', { ...userInfo, municipio: "", estado: "", parroquia: "" });
    this._localStorageService.set('cedulaCliente', {
      Cedula: `${identidad} (${nro_contrato})`,
      NContrato: nro_contrato,
      id:1,
      identidad
    });
  }

  public noRegisterdUser() {
    const users = this._localStorageService.get('user');
    if (!users) {
      return true;
    }
    return false;
  }

  public login(userInfo: { email: string, password: string }) {
    const registeredUser = this._localStorageService.get('user');
    if (!registeredUser) {
      return false;
    }
    const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase() && userInfo.password.replace(/[^\d]/gi, '') === registeredUser.password.replace(/[^\d]/gi, ''));
    if (!validUser) {
      return false;
    }

    this._localStorageService.set('auth-user', userInfo);
    return true;
  }

  modifyUser(userInfo: any) {
    const user = this._localStorageService.get('user');
    let userInfoUpdate = { ...user, ...userInfo };
    this._localStorageService.set('user', userInfoUpdate);
    this.userState.emit(userInfoUpdate);
  }

  public logout() {
    this._localStorageService.remove('auth-user');
  }

  public async loginCRM(email: string, password: string) {
    //http://localhost/phpass.php?email=HADADJORGE1@GMAIL.COM&pass=jermsof
    try {
      const responseCliente: any = await this.http.get(`${environment.apiPhP}email=${email}&pass=${password}`).toPromise();
      if (!responseCliente) {
        return { status: false };
      }

      this._localStorageService.set('HaveFibexP', responseCliente.fibex_play)

      return responseCliente;

    } catch (error) {
      this._logs.insertarError(error.message);
      return { status: false };
    }
  }

  public async resetPassword(email: string, password: string) {
    try {
      const response: any = await this.http.get(`${environment.apiResetPassword}email=${email}&pass=${password}`).toPromise();
      return response;
    } catch (error) {
      this._logs.insertarError(error.message);
      return {
        status: false,
        email: null,
        password: null,
      }
    }
  }

  UpdateEmail(Cedula: any, Email: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        this.http.get(`${environment.apiCRM}UpdateEmail/?ci=${Cedula}&email=${Email}`).subscribe((ResUp: any) => {
          resolve(ResUp)
        }, (erro: any) => {
          reject(erro)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  AcualizarDatos(Cedula: any, Email: undefined | string, Phone?: string) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const consultaData: any = await this._consulta.GetSaldoCedula(Cedula).then((response: any) => {
          const body = {
            "Cedula": Cedula,
            "id_Contrato": response[0].id_contrato,
            "Email": Email,
            "Lic": "584120202020@c.us",
            "Telefono": Phone
          }

          // Encabezados de la petición
          const headersData = {
            directory: "SAE",
            method: `ActualizarDatos`,
            token: environment.TokenUpdate,
            platform: "App"
          };

          this.security.EncrypDataHash(headersData).then((headers: any) => { // encabezados encriptados
            this.security.EncrypDataHash(body).then((DataBody: any) => { // encabezados encriptados
              this.http.post(environment.URLApi, DataBody, { headers }).subscribe((ResLog: any) => {
                resolve(this.security.Descryp(ResLog))
              }, err => { console.error(err) })
            }).catch((err) => console.error(err));
          }).catch((err) => console.error(err));

        });

      } catch (error) {
        reject(error)
      }
    })
  }

  public async verifyCedula(cedula: string) {
    try {
      const response: any = await this.http.get(`${environment.apiVerifyCedula}cedula=${cedula}`).toPromise();
      return response;
    } catch (error) {
      this._logs.insertarError(error.message);
      return {
        status: false,
        cedula: null,
      };
    }
  }

  public async querySecurityQuestions(email: string, opTipe: number = 2, preguntaUno: string = "", respuestaUno: string = "", preguntaDos: string = "", respuestaDos: string = "") {
    try {
      const response: any = await this.http.get(`${environment.apiSecurityQuestions}email=${email}&opTipe=${opTipe}&preguntaUno=${preguntaUno}&respuestaUno=${respuestaUno}&preguntaDos=${preguntaDos}&respuestaDos=${respuestaDos}`).toPromise();
      if (!response.status) {
        return {
          status: false,
          message: response.message,
          data: null,
        };
      }
      return response;

    } catch (error) {
      this._logs.insertarError(error.message);
      return {
        status: false,
        message: "Las preguntas de seguridad no pudieron ser consultadas.",
        data: null,
      };
    }

  }

}