import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
    console.log = function () { }; //Comentado por los momentos para hacer pruebas | Diego Bastardo
    console.error = function () { };
    console.warn = function () { };
}
// CANCELA TODOS LOS MENSAJES DE LA CONSOLA
console.log = function () { };
console.error = function () { };
console.warn = function () { };
__NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(err => console.error(err));
