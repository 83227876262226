import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment.prod';
import { LogErrorsService } from './log-errors.service';


@Injectable({
  providedIn: 'root'
})
export class ConsultaPagoMovilService {

  constructor(
    private _http: HttpClient,
    private _logs: LogErrorsService, 
  ) { }

  async consultaBancos() {
    try {
       const response:any = await this._http.get(`${environment.apiDbMaps}find-all-info/tmBancos`).toPromise();
       return { 
         error: false,
         data : response,
       };
    } catch (error) {
     this._logs.insertarError(error.message);
      return { 
        error: true, 
       data: []
     }
    }
    
  }
}