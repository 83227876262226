import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LogErrorsService } from './log-errors.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./log-errors.service";
export class GuiaProgramacionService {
    constructor(http, _logs) {
        this.http = http;
        this._logs = _logs;
        this.guideState = new EventEmitter();
        this.guide = null;
    }
    getData() {
        return this.guide;
    }
    loadGuia() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.http.get(environment.apiGuia).toPromise();
                this.guide = response;
                this.guideState.emit(response);
            }
            catch (error) {
                this._logs.insertarError(error.message);
                this.guideState.emit({ error: true });
                this.guide = {
                    error: true,
                };
            }
        });
    }
}
GuiaProgramacionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GuiaProgramacionService_Factory() { return new GuiaProgramacionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LogErrorsService)); }, token: GuiaProgramacionService, providedIn: "root" });
