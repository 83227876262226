import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';


import { OptListComponent } from './opt-list/opt-list.component';
import { FilterComponent } from './filter/filter.page';
import { SliderComponent } from './slider/slider.component';
import { AccordionComponent } from './accordion/accordion.component';

@NgModule({
  declarations: [
    OptListComponent,
    FilterComponent,
    SliderComponent,
    AccordionComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule    
  ],
  exports: [
    OptListComponent,
    FilterComponent,
    SliderComponent,
    AccordionComponent
  ]
})
export class ComponentsModule { }
