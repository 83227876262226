import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';
import { ConsultasService } from '../../../services/consultas.service';
import { ComunicationObserverService } from '../../../services/comunication-observer.service';
import { Router } from '@angular/router';
export class CallMePage {
    constructor(modalCtrl, formBuilder, _consultas, alert, comunicationService, router) {
        this.modalCtrl = modalCtrl;
        this.formBuilder = formBuilder;
        this._consultas = _consultas;
        this.alert = alert;
        this.comunicationService = comunicationService;
        this.router = router;
        this.fromClubFibex = false;
        this.CallForm = this.formBuilder.group({
            'Phone': ['', [Validators.required, Validators.minLength(6)]]
        });
        this.fromClubFibex = (router.url.includes('club-fibex')); // if url is from Club Fibex
    }
    get PhoneClient() { return this.CallForm.get('Phone').value; }
    ngOnInit() {
        console.log(this.DataClient);
        console.log('Is from club Fibex', this.fromClubFibex);
    }
    Call() {
        try {
            const DataNotif = {
                tipo: 'notif',
                accion: this.fromClubFibex ? 'llamar ClubFibex' : 'llamar',
                PhoneClient: this.PhoneClient,
                cedula: this.DataClient.cedula
            };
            this._consultas.SendMsgwhatsapp(DataNotif).then((Res) => {
                if (Res.status) {
                    this.closeModal();
                    this.comunicationService.disabledBtn$.emit(Res.status);
                }
                // ! verificar el objetivo de esta condicional
                // if (!this.DataClient.phonenumber.includes(this.PhoneClient)) {
                //   this._consultas.SendMsgwhatsapp(DataNotif).then((Res: any) => {
                //     this.createAlert('Solicitud enviada', 'Su solicitud de cambio de correo ha sido enviada')
                //   })
                // } else {
                //   this.createAlert('Solicitud enviada', 'Su solicitud de cambio de correo ha sido enviada')
                // }
            });
        }
        catch (error) {
            console.error(error);
        }
    }
    createAlert(Header, Msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alert.create({
                header: Header,
                message: Msg,
                cssClass: 'secondary cust',
                buttons: [
                    {
                        text: 'Cerrar',
                        handler: (data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            this.closeModal();
                        })
                    },
                ]
            });
            alert.present();
        });
    }
    closeModal() {
        this.modalCtrl.dismiss();
    }
}
