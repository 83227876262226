import * as i0 from "@angular/core";
export class DateCompareService {
    constructor() { }
    timeDifference(date1, date2) {
        var difference = date1 - date2;
        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24;
        var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60;
        var minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60;
        var secondsDifference = Math.floor(difference / 1000);
        return {
            days: daysDifference,
            hours: hoursDifference,
            minutes: minutesDifference,
            seconds: secondsDifference
        };
    }
}
DateCompareService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DateCompareService_Factory() { return new DateCompareService(); }, token: DateCompareService, providedIn: "root" });
