import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AlertController, IonSelect } from '@ionic/angular';
import { WebsocketAPI } from '../../../../app/lib/websocket.api';
import { environment } from '../../../../environments/environment';
import { HelperService } from '../../../services/helper.service';
import { LocalStorageService } from '../../../services/local-storage.service';
export class ModalShowQrComponent {
    constructor(helper, localStorage, alertCtrl) {
        this.helper = helper;
        this.localStorage = localStorage;
        this.alertCtrl = alertCtrl;
        this.cuponname = null;
        this.imgproduct = null;
        this.qrcode = null;
        this.qrimage = null;
        this.couponCount = 0;
        this.isQRListView = false;
        this.alreadyGenerated = false;
        this.generateQR = new EventEmitter();
        this.closeButton = new EventEmitter();
        this.acceptButton = new EventEmitter();
        this.acceptCuponSuccessed = new EventEmitter();
        this.setCurrentBranch = new EventEmitter();
        this.socket = null;
        this.succesedCupon = false;
        this.userData = this.localStorage.get('CedulaActual');
    }
    ngOnInit() {
        // console.log("HERE MAN PEDRO FIGUEROA");
        console.log('QR IMAGE');
        console.log(this.qrimage);
        this.FinishWebsocketNotificationQR();
        console.log(this.couponCount);
    }
    ngOnDestroy() {
        this.succesedCupon = false;
        this.FinishWebsocketNotificationQR();
    }
    ngOnChanges(changes) {
        const qrcode = changes["qrcode"];
        if (qrcode && qrcode.previousValue !== qrcode.currentValue) {
            this.FinishWebsocketNotificationQR();
            if (qrcode.currentValue) {
                this.InitWebsocketNotificationQR();
            }
        }
    }
    openBranchOfficeSelector() {
        this.branchOfficeSelector.open();
    }
    /**
     * Inicializar servicio para notificar QR
     */
    InitWebsocketNotificationQR() {
        // Iniciar
        if (this.qrcode && !this.socket && !this.succesedCupon) {
            // Crear conexión hacia al servidor
            // console.log("InicializedSocketNotification");
            this.socket = new WebsocketAPI(environment.apiTestWS + "/qr-notify/" + this.qrcode);
            this.socket.onclose = () => {
                // eliminar la variable al cerrar la conexión para permitir la entrada de esta funcion nuevamente
                // console.log("FinishedSocketNotification");
                delete this.socket;
                if (this.qrcode) {
                    // Reintentar a los 3 segundos
                    setTimeout(() => {
                        this.InitWebsocketNotificationQR();
                    }, 3000);
                }
            };
            // Manejar eventos (actualmente 1)
            this.socket.onmessage = (event, data) => {
                switch (event) {
                    case "notify_redeemed_coupon":
                        // Cupon canejado correctamente (ES NECESARIO ACTUALIZAR)
                        // ...
                        // alert("CUPON ESCANEADO CON EXITO");
                        this.succesedCupon = true;
                        this.socket.close(); // !!!! IMPORTANTE CERRAR LA CONEXIÓN  AL RECIBIR ESTE EVENTO, PARA QUE NO QUEDE EN EL AIRE
                        break;
                }
            };
        }
    }
    FinishWebsocketNotificationQR() {
        if (this.socket) {
            try {
                this.socket.close();
            }
            catch (err) {
                console.error(err);
            }
            delete this.socket;
        }
    }
    downloadQR() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { identidad } = this.userData;
            // this.helper.sendBillsByWhatsApp()
            const alertCtrl = yield this.alertCtrl.create({
                header: 'Cupón enviado',
                message: 'En breve recibirá en su dispositivo móvil el cupón seleccionado.',
                cssClass: 'secondary cust',
                buttons: [
                    {
                        text: 'Cerrar',
                        role: 'Cancelar',
                        cssClass: 'cust',
                    }
                ]
            });
            alertCtrl.present();
        });
    }
}
