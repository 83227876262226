import { CedulaStorage } from './cedula-storage.service';
import * as i0 from "@angular/core";
import * as i1 from "./cedula-storage.service";
export class ManageCuentaService {
    constructor(_cedulaStorage) {
        this._cedulaStorage = _cedulaStorage;
        this.current = null;
        if (!this.current) {
            this.getFisrt();
        }
    }
    setCurrent(cuenta) {
        this.current = cuenta;
    }
    getFisrt() {
        try {
            this.current = this._cedulaStorage.getCedulas()[0];
        }
        catch (error) {
            this.current = null;
        }
    }
}
ManageCuentaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManageCuentaService_Factory() { return new ManageCuentaService(i0.ɵɵinject(i1.CedulaStorage)); }, token: ManageCuentaService, providedIn: "root" });
