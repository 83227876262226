import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
import { Router } from '@angular/router';
export class Card3Component {
    constructor(ClubFibexServ, router) {
        this.ClubFibexServ = ClubFibexServ;
        this.router = router;
        this.title = '';
        this.description = '';
        this.image = '';
    }
    ngOnInit() { }
    addFavoriteItem() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.item)
                yield this.ClubFibexServ.handleProductFav(this.item);
        });
    }
    goToRestDetail() {
        this.router.navigate(['club-fibex', 'product-details', this.item.id, false]);
    }
    subtractToNumber(num1, num2) {
        return Number(num1) - Number(num2);
    }
}
