<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Actualizacion Disponible</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card class="bg-white">
    <ion-card-content class="ion-padding">
      <ion-text> {{Mensaje}} </ion-text>
    </ion-card-content>
    <ion-card-content class="ion-padding">
      <ion-button size="full" color="primary" (click)="GoToUpdate()">
        <ion-ripple-effect type="unbounded"></ion-ripple-effect>
        <!-- <ion-icon name="easel" style="margin-right: 10px"></ion-icon> -->
        Actualizar
      </ion-button>
    </ion-card-content>
  </ion-card>

</ion-content>