import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { LocalStorageService } from './local-storage.service';
import { LogErrorsService } from './log-errors.service';
import { ConsultasService } from './consultas.service';
import { SeguridadDatos } from './bscript.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./local-storage.service";
import * as i3 from "./log-errors.service";
import * as i4 from "./consultas.service";
import * as i5 from "./bscript.service";
export class UserAuthenticationService {
    constructor(http, _localStorageService, _logs, _consulta, security) {
        this.http = http;
        this._localStorageService = _localStorageService;
        this._logs = _logs;
        this._consulta = _consulta;
        this.security = security;
        this.userState = new EventEmitter();
    }
    getUser() {
        return this._localStorageService.get('user') || null;
    }
    registerUser(userInfo) {
        this._localStorageService.removeAll();
        this._createAccount(userInfo);
        this.login({ email: userInfo.email, password: userInfo.password });
        this.userState.emit(Object.assign({}, userInfo, { municipio: "", estado: "", parroquia: "" }));
        return true;
    }
    _createAccount(userInfo) {
        let user = userInfo;
        const { identidad, nro_contrato } = user;
        console.log(user);
        this._localStorageService.set('user', Object.assign({}, userInfo, { municipio: "", estado: "", parroquia: "" }));
        this._localStorageService.set('cedulaCliente', {
            Cedula: `${identidad} (${nro_contrato})`,
            NContrato: nro_contrato,
            id: 1,
            identidad
        });
    }
    noRegisterdUser() {
        const users = this._localStorageService.get('user');
        if (!users) {
            return true;
        }
        return false;
    }
    login(userInfo) {
        const registeredUser = this._localStorageService.get('user');
        if (!registeredUser) {
            return false;
        }
        const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase() && userInfo.password.replace(/[^\d]/gi, '') === registeredUser.password.replace(/[^\d]/gi, ''));
        if (!validUser) {
            return false;
        }
        this._localStorageService.set('auth-user', userInfo);
        return true;
    }
    modifyUser(userInfo) {
        const user = this._localStorageService.get('user');
        let userInfoUpdate = Object.assign({}, user, userInfo);
        this._localStorageService.set('user', userInfoUpdate);
        this.userState.emit(userInfoUpdate);
    }
    logout() {
        this._localStorageService.remove('auth-user');
    }
    loginCRM(email, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //http://localhost/phpass.php?email=HADADJORGE1@GMAIL.COM&pass=jermsof
            try {
                const responseCliente = yield this.http.get(`${environment.apiPhP}email=${email}&pass=${password}`).toPromise();
                if (!responseCliente) {
                    return { status: false };
                }
                this._localStorageService.set('HaveFibexP', responseCliente.fibex_play);
                return responseCliente;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return { status: false };
            }
        });
    }
    resetPassword(email, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.http.get(`${environment.apiResetPassword}email=${email}&pass=${password}`).toPromise();
                return response;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    status: false,
                    email: null,
                    password: null,
                };
            }
        });
    }
    UpdateEmail(Cedula, Email) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.http.get(`${environment.apiCRM}UpdateEmail/?ci=${Cedula}&email=${Email}`).subscribe((ResUp) => {
                    resolve(ResUp);
                }, (erro) => {
                    reject(erro);
                });
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    AcualizarDatos(Cedula, Email, Phone) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const consultaData = yield this._consulta.GetSaldoCedula(Cedula).then((response) => {
                    const body = {
                        "Cedula": Cedula,
                        "id_Contrato": response[0].id_contrato,
                        "Email": Email,
                        "Lic": "584120202020@c.us",
                        "Telefono": Phone
                    };
                    // Encabezados de la petición
                    const headersData = {
                        directory: "SAE",
                        method: `ActualizarDatos`,
                        token: environment.TokenUpdate,
                        platform: "App"
                    };
                    this.security.EncrypDataHash(headersData).then((headers) => {
                        this.security.EncrypDataHash(body).then((DataBody) => {
                            this.http.post(environment.URLApi, DataBody, { headers }).subscribe((ResLog) => {
                                resolve(this.security.Descryp(ResLog));
                            }, err => { console.error(err); });
                        }).catch((err) => console.error(err));
                    }).catch((err) => console.error(err));
                });
            }
            catch (error) {
                reject(error);
            }
        }));
    }
    verifyCedula(cedula) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.http.get(`${environment.apiVerifyCedula}cedula=${cedula}`).toPromise();
                return response;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    status: false,
                    cedula: null,
                };
            }
        });
    }
    querySecurityQuestions(email, opTipe = 2, preguntaUno = "", respuestaUno = "", preguntaDos = "", respuestaDos = "") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.http.get(`${environment.apiSecurityQuestions}email=${email}&opTipe=${opTipe}&preguntaUno=${preguntaUno}&respuestaUno=${respuestaUno}&preguntaDos=${preguntaDos}&respuestaDos=${respuestaDos}`).toPromise();
                if (!response.status) {
                    return {
                        status: false,
                        message: response.message,
                        data: null,
                    };
                }
                return response;
            }
            catch (error) {
                this._logs.insertarError(error.message);
                return {
                    status: false,
                    message: "Las preguntas de seguridad no pudieron ser consultadas.",
                    data: null,
                };
            }
        });
    }
}
UserAuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAuthenticationService_Factory() { return new UserAuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LocalStorageService), i0.ɵɵinject(i3.LogErrorsService), i0.ɵɵinject(i4.ConsultasService), i0.ɵɵinject(i5.SeguridadDatos)); }, token: UserAuthenticationService, providedIn: "root" });
