import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CallMePage } from '../../../pages/modal/call-me/call-me.page';
import { ModalController } from '@ionic/angular';
import { UserInterface } from '../../../interfaces/userInfo';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { Router } from '@angular/router';
import { RoutingAppService } from '../../../services/routing-app.service';
import { FilterComponent } from '../../../components/filter/filter.page';
import { SubscriptionGraphqlService } from '../../../services/subscription-graphql.service';

@Component({
  selector: 'app-club-header',
  templateUrl: './club-header.component.html',
  styleUrls: ['./club-header.component.scss'],
})
export class ClubHeaderComponent implements OnInit {

  @Input() showSearchBar: boolean = false;
  @Input() showFilterBtn: boolean = false;
  @Input() showLightMode: boolean = false;
  @Output() navigateToHome: EventEmitter<void> = new EventEmitter<void>()
  @Output() goToSearchEmit: EventEmitter<void> = new EventEmitter<void>()
  @Output() handleSearchEmit: EventEmitter<Event> = new EventEmitter<Event>()
  public user: UserInterface;
  public NewMsg: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private athentication: UserAuthenticationService,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _routing: RoutingAppService,
  ) {
    this.user = this.athentication.getUser();
  }

  ngOnInit() {
    this._SubscriptionGraphqlService.chatState.subscribe((data) => {
      this.NewMsg = true;
    });
  }

  async callClient(): Promise<void> {

    const modal = await this.modalCtrl.create({
      component: CallMePage,
      componentProps: { DataClient: {cedula:this.user.identidad}, fromClubFibex: true },
      mode: 'ios'
    });
    await modal.present();
  }

  public goToClubIframe(): void {
    this.router.navigate(['club-fibex', 'club-iframe'])
  }

  public navegateHome(): void {
    this.navigateToHome.emit()
  }

  async openFilterModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: FilterComponent,
      cssClass: 'full-screen-modal'
    })

    modal.present()
  }

  public goToSearch(): void {
    this.goToSearchEmit.emit()
  }

  public handleSearch(event: Event): void {
    this.handleSearchEmit.emit(event);
  }

  goToChat() {
    this.NewMsg = false;
    this._routing.ChangeRuta("chat");
  }
}
