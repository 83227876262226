import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ComunicationObserverService {

  disabledBtn$ = new EventEmitter<boolean>();

  constructor() { }  
}
