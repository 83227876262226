<ion-header>
  <ion-toolbar color="primary">
    <ion-buttons slot="start">
      <ion-back-button text=""></ion-back-button>
    </ion-buttons>
    <ion-title>Soporte - Reporte de avería</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content [scrollEvents]="true" (ionScroll)="handleShowScrollArrow($event)">
  <ion-button 
    shape="round"
    expand="full"
    class="ion-margin custom"
    (click)="openReporteModal()"
    
  >
  Reportar
</ion-button>
  <ion-card class="bg-white ion-padding">
    <ion-text><strong>Historial de reporte</strong></ion-text>
  </ion-card>
  <ion-card class="bg-white">
    <ion-card-content *ngIf="tableSoport.length" class="ion-no-padding">
      <ion-grid class="ion-margin" style="margin: 0; padding: 8px 0;">
        <ion-row class="ion-text-center" style="display: flex; align-items: center;">
          <ion-col># Orden</ion-col>
          <ion-col>Estatus</ion-col>
          <ion-col>Fecha</ion-col>
          <ion-col></ion-col>
        </ion-row>
                  
        <ion-row *ngFor="let item of tableSoport" (click)="openSuportModal(item)" style="display: flex; align-items: center;">
            <ion-col style="font-size: 12px;"><strong style="color: #2f426e">{{ item.nro_orden }}</strong></ion-col>
            <ion-col style="font-size: 12px;">{{ item.status_orden }}</ion-col>
            <ion-col style="font-size: 12px;">{{ item.fecha_orden }}</ion-col>  
            <ion-col style="width: 0%;">
              <ion-button shape="round" class="custom" size="small" style="font-size: 11px;">
               Ver
              </ion-button></ion-col>  
        </ion-row>
      </ion-grid>
        
    </ion-card-content>
    <ion-card-content *ngIf="!tableSoport.length">
      <ion-text>
        No tiene ningun reporte registrado aún.
      </ion-text>
    </ion-card-content>
  </ion-card>
  
 <!-- <iframe
  [src]="secureUrl | url "
  style="
    width:100%;
    height: 99%;
  "
></iframe> 
  -->  <!--
    <div class="ion-text-center bg-profile">
      <img src="assets/img/support-app.jpg">
    </div>
    <ion-card class="ion-no-margin bg-white">
      <ion-card-content>
        <ion-list>
          <ion-item href="tel:0212-405.02.11">
            <ion-icon name="call" slot="start" color="primary"></ion-icon>
            <ion-label>
              <p><ion-text color="primary">CARACAS:</ion-text></p>
              <h2><ion-text color="dark">0212-405.02.11</ion-text></h2>
            </ion-label>
          </ion-item>
          <ion-item href="tel:0241-451.66.05">
            <ion-icon name="call" slot="start" color="primary"></ion-icon>
            <ion-label>
              <p><ion-text color="primary">VALENCIA:</ion-text></p>
              <h2><ion-text color="dark">0241-451.66.05</ion-text></h2>
            </ion-label>
          </ion-item>
          <ion-item href="tel:0241-451.66.05">
            <ion-icon name="call" slot="start" color="primary"></ion-icon>
            <ion-label>
              <p><ion-text color="primary">MARACAY:</ion-text></p>
              <h2><ion-text color="dark">0243-431.57.77</ion-text></h2>
            </ion-label>
          </ion-item>
          <ion-item href="tel:0241-451.66.05">
            <ion-icon name="call" slot="start" color="primary"></ion-icon>
            <ion-label>
              <p><ion-text color="primary">PTO CABELLO:</ion-text></p>
              <h2><ion-text color="dark">0242-361.91.01</ion-text></h2>
            </ion-label>
          </ion-item>
          <ion-item href="mailto:support@ionbooking2.ionic">
            <ion-icon name="mail" slot="start" color="primary"></ion-icon>
            <ion-label>
              <p><ion-text color="primary">Email</ion-text></p>
              <h2><ion-text color="dark">atencionalcliente@cablehogar.net</ion-text></h2>
            </ion-label>
          </ion-item>
        </ion-list>
          
      </ion-card-content>
    </ion-card>

  -->

  <button class="arrow_up animated faster" (click)="scrollToTop()" [class.fadeInUp]="showScrollArrow"
    [class.fadeOutDown]="!showScrollArrow">
    <ion-icon name="chevron-up-outline"></ion-icon>
  </button>

</ion-content>

<ion-footer class="animated fadeIn">
  <ion-toolbar color="primary">
    <ion-grid>
      <ion-row>
        <ion-col size="6" class="ion-no-padding">
          <ion-button
            size="small"
            shape="round"
            expand="full"
            fill="clear"
            color="tertiary"
            routerLink="/home"
          >
            <ion-icon slot="start" name="wallet"></ion-icon>
            <span class="icon-desc">
              {{ 'app.pages.menu.rentacar' | translate }}
            </span>
          </ion-button>
        </ion-col>
        <!-- <ion-col size="4" class="ion-no-padding">
          <ion-button
            size="small"
            shape="round"
            expand="full"
            fill="clear"
            color="tertiary"
            routerLink="/favorites"
          >
            <ion-icon slot="start" name="Cash"></ion-icon>
            <span class="icon-desc">
              {{ 'app.pages.menu.activitiestab' | translate }}
            </span>
          </ion-button>
        </ion-col> -->
        <ion-col size="6" class="ion-no-padding">
          <ion-button
            size="small"
            shape="round"
            expand="full"
            fill="clear"
            color="tertiary"
            (click)="editprofile()"
          >
            <ion-icon slot="start" name="person-circle"></ion-icon>
            <span class="icon-desc">
              Perfil
            </span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>