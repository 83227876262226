import * as tslib_1 from "tslib";
import { OnInit, } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { LocalStorageService } from '../../../services/local-storage.service';
export class PinValidationPage {
    constructor(modalCtrl, _localStorageService, formBuilder, alert) {
        this.modalCtrl = modalCtrl;
        this._localStorageService = _localStorageService;
        this.formBuilder = formBuilder;
        this.alert = alert;
    }
    ngOnInit() {
        this.pinValidationForm = this.formBuilder.group({
            'pinValue': [null, Validators.compose([
                    Validators.required
                ])],
        });
    }
    getPin() {
        const pinregister = this._localStorageService.get('pinRegister');
        if (!pinregister) {
            this.createAlert('Aun no ha creado un  pin de acceso', 'Para acceder a contenido restringido necesita crear un pin de acceso, pulse el botton de "Control parental" para crearlo.');
            this.closeModal();
            return;
        }
        const isValid = pinregister === this.pinValidationForm.value.pinValue;
        if (isValid) {
            this.modalCtrl.dismiss("OK");
            return;
        }
        else {
            this.createAlert('Pin inválido', 'El pin es incorrecto, por favor introduzca el pin de acceso a contenido restringido');
        }
        this.closeModal();
    }
    createAlert(header, message = '') {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alert.create({
                header,
                message,
                cssClass: 'secondary cust',
                buttons: [
                    {
                        text: 'Cerrar',
                        role: 'Cancelar',
                        cssClass: 'secondary',
                    }
                ]
            });
            alert.present();
        });
    }
    closeModal() {
        this.modalCtrl.dismiss();
    }
}
