import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PushMessagesService } from '../../../services/push-messages.service';
import { PushMessageInterface } from '../../../interfaces/push-message';
import { Router } from '@angular/router';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-push-message-list',
  templateUrl: './push-message-list.component.html',
  styleUrls: ['./push-message-list.component.scss'],
})
export class PushMessageListComponent implements OnInit {

  public messages: PushMessageInterface[] | undefined;
  public isPushUrlView: boolean = false;

  constructor(
    private pushMessageService: PushMessagesService,
    private router: Router,
    public popoverController: PopoverController,
    private navCtrl: NavController
  ) {
    this.messages = this.pushMessageService.messages;
    if (this.router.url.includes('push-message')) this.isPushUrlView = true;
  }

  ngOnInit() {
    console.log(this.messages);
  }

  handleActiveMessage(message: PushMessageInterface) {
    const content = document.getElementById(`item_id_${message.id}`);
    console.log(content.className)
    if (content.className.includes('item_closed')) {
      content.classList.remove('item_closed')
      content.classList.add('item_active')//Abre el contenido del item seleccionado
    }
    else {
      content.classList.remove('item_active')
      content.classList.add('item_closed')
    }

    if (!message.isRead) message.isRead = true;
  }

  public goToPushMessagePage(): void {
    // this.router.navigateByUrl('/push-message')
    this.popoverController.dismiss()
    this.navCtrl.navigateRoot('/push-message')
  }

  public markAllAsRead(): void {
    this.messages.map(message => {
      message.isRead = true;
    })
  }

  public getMessageNotReadLength(): number {
    return this.messages.filter(message => message.isRead === false ).length
  }

  public goToBack(): void {
    this.navCtrl.back()
  }

}
