import { Component, HostListener, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.page.html',
  styleUrls: ['./general-information.page.scss'],
})
export class GeneralInformationPage implements OnInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getScreenSizeByPx()
}
  isLittleMobile: boolean = false;
  isBigMobile: boolean = true;
  isTablet: boolean = false;
  isDesktop: boolean = false;

  constructor(
    private modal: ModalController,
  ) {
    this.getScreenSizeByPx()
  }

  ngOnInit() {
  }

  close() {
    this.modal.dismiss();
  }

  getScreenSizeByPx(): void {
    this.isLittleMobile = this.isBigMobile = this.isTablet = this.isDesktop = false;
    window.screen.availWidth <= 310 ? this.isLittleMobile = true : window.screen.availWidth <= 470 ? this.isBigMobile = true : window.screen.availWidth <= 768 ? this.isTablet = true : this.isDesktop = true; 
  }

}
