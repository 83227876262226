import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { DummyService } from '../../services/dummy.service';
import { GetCategoriesInteface } from '../../interfaces/product.interface';
import { ClubFibexFeedingService } from '../../services/club-fibex-feeding.service';
import { ClassGetter } from '@angular/compiler/src/output/output_ast';
import { HelperService } from '../../services/helper.service';
import { RoutingAppService } from '../../services/routing-app.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.page.html',
  styleUrls: ['./filter.page.scss'],
})
export class FilterComponent implements OnInit {

  groupCategory: string[] = [];
  activeCat: any[] = [];
  rate: number | string = 'all';
  price: string = 'all';
  rangePrices: string[] = ['1$', '10$', '50$', '100$', '+100$']
  stars: { id: number;url: string;urlBlanco: string;}[] = [];
  public categories: GetCategoriesInteface[] = [];
  public StarsSelected: any[] = [];
  minRange: string = '0';
  maxRange: string = '1000';

  constructor(
    private router : Router,
    private modalController : ModalController,
    private dummy: DummyService,
    private _routing: RoutingAppService,
    private clubFibexService: ClubFibexFeedingService,
    private helper: HelperService
  ) {
    this.initFn();
  }

  async ngOnInit() {
    this.stars = this.dummy.starsFilter;
    // this.categories = this.dummy.categoriesStack
    this.categories = await this.clubFibexService.GetCategories()
    console.log("categories", this.categories);
    

  }

  public addActiveCat(category: GetCategoriesInteface) {

    const index = this.activeCat.findIndex( cat => cat.idEmpCat === category.idEmpCat )
    if ( index === -1 ) {
       this.activeCat.push(category);
    } else {
      this.activeCat = this.activeCat.filter( cat => cat.idEmpCat !== category.idEmpCat)
    }
  }


  public addActiveStar( star: any ) {
    let index = this.StarsSelected.findIndex(sta => sta.id === star.id)
    if ( index === -1 ) {
       this.StarsSelected.push(star);
    } else {
      this.StarsSelected = this.StarsSelected.filter( element => element.id !== star.id );
    }
  }

  private async initFn() {
    const categories:GetCategoriesInteface[] = await this.clubFibexService.GetCategories()
    this.groupCategory = categories.map((category:GetCategoriesInteface) => category.nombre);
  }

  onBackNavigation(){
    this.modalController.dismiss();
  }

  onNavigation(){
    const arrArg = []

    this.activeCat.forEach( element => {
      if( element.idEmpCat ) arrArg.push(element.idEmpCat); 
    });
    
    const qParam = arrArg.join('--')
    // console.log(qParam)
    // console.log(qParam.length)

    this.modalController.dismiss();
    this.router.navigate(
      ['/club-fibex/product-list'], 
      { 
        queryParams: { 
          qCategory: qParam, 
          qPricing: `${ this.minRange }--${ this.maxRange }`
        },
        replaceUrl: true,
      }
    );
  }

  setStarSelected( item: any ) {
    this.StarsSelected = item.id;
  }

  trigger(ev: any) {
    const { detail } = ev;
    const { value } = detail;
    this.minRange = `${value.lower}`;
    this.maxRange = `${value.upper}`;
  }

  public hasSelectedCat(category:string): boolean {
    return (this.activeCat.includes(category));
  }

  public hasSelectedStar(star:number): boolean {
    return (this.StarsSelected.includes(star));
  }
}
