/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./propaganda.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/url.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i5 from "@ionic/angular";
import * as i6 from "@angular/common";
import * as i7 from "./propaganda.page";
var styles_PropagandaPage = [i0.styles];
var RenderType_PropagandaPage = i1.ɵcrt({ encapsulation: 0, styles: styles_PropagandaPage, data: {} });
export { RenderType_PropagandaPage as RenderType_PropagandaPage };
function View_PropagandaPage_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "iframe", [["id", "reporte"], ["style", "\n    width:100%;\n    height: 100vh;\n    z-index: 1;\n    "]], [[8, "src", 5]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), _co.secureUrl)); _ck(_v, 0, 0, currVal_0); }); }
export function View_PropagandaPage_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UrlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ion-content", [], null, null, null, i4.View_IonContent_0, i4.RenderType_IonContent)), i1.ɵdid(2, 49152, null, 0, i5.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PropagandaPage_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.secureUrl != ""); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_PropagandaPage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-propaganda", [], null, null, null, View_PropagandaPage_0, RenderType_PropagandaPage)), i1.ɵdid(1, 114688, null, 0, i7.PropagandaPage, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PropagandaPageNgFactory = i1.ɵccf("app-propaganda", i7.PropagandaPage, View_PropagandaPage_Host_0, {}, {}, []);
export { PropagandaPageNgFactory as PropagandaPageNgFactory };
