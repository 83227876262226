import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { LettersService } from '../../../../services/letters.service';
import { ClubFibexFeedingService } from '../../../../services/club-fibex-feeding.service';
export class ModalLocationComponent {
    constructor(letterService, clubService) {
        this.letterService = letterService;
        this.clubService = clubService;
        this.close = new EventEmitter();
        this.franqSelectedEvent = new EventEmitter();
        this.franquicias = [];
        this.filteredItems = [];
        this.FranqSelectedId = [];
        this.toString = (object) => {
            return JSON.stringify(object);
        };
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.initData();
        });
    }
    initData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.franquicias = yield this.clubService.GetAllFranquicias();
            console.log('franquicias', this.franquicias);
            this.filteredItems = [...this.franquicias];
        });
    }
    trackItems(index, item) {
        return item.idEnSAE;
    }
    cancel() {
        this.close.emit();
    }
    confirmChanges() {
        this.franqSelectedEvent.emit(this.FranqSelectedId);
        /*
         !En caso que se requiera guardar las franquicias en el localStorage
         this.localStorageService.set('user_franq', this.FranqSelectedId || [])
        */
    }
    searchbarInput(ev) {
        this.filterList(ev.target.value);
    }
    /**
     * Update the rendered view with
     * the provided search query. If no
     * query is provided, all data
     * will be rendered.
     */
    filterList(searchQuery) {
        /**
         * If no search query is defined,
         * return all options.
         */
        if (searchQuery === undefined) {
            this.filteredItems = [...this.franquicias];
        }
        else {
            /**
             * Otherwise, normalize the search
             * query and check to see which items
             * contain the search query as a substring.
             */
            const normalizedQuery = searchQuery.toLowerCase();
            this.filteredItems = this.franquicias.filter((item) => {
                return item.Nombre.toLowerCase().includes(normalizedQuery);
            });
        }
    }
    isChecked(value) {
        return this.FranqSelectedId.find((item) => item === value);
    }
    checkboxChange(ev) {
        console.log('ev', ev);
        const checked = ev.target.checked;
        const value = ev.target.value;
        if (checked) {
            this.FranqSelectedId = [...this.FranqSelectedId, value];
        }
        else {
            this.FranqSelectedId = this.FranqSelectedId.filter((franquicia) => franquicia !== value);
        }
    }
}
