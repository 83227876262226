import { activities } from './mock-activities';
import * as i0 from "@angular/core";
export class ActivitiesService {
    constructor() {
        this.activities = activities;
    }
    getAll() {
        return this.activities;
    }
    getItem(id) {
        for (var i = 0; i < this.activities.length; i++) {
            if (this.activities[i].id === parseInt(id)) {
                return this.activities[i];
            }
        }
        return null;
    }
    remove(item) {
        this.activities.splice(this.activities.indexOf(item), 1);
    }
}
ActivitiesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivitiesService_Factory() { return new ActivitiesService(); }, token: ActivitiesService, providedIn: "root" });
