import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list3',
  templateUrl: './list3.component.html',
  styleUrls: ['./list3.component.scss'],
})
export class List3Component implements OnInit {

  constructor() { }

  ngOnInit() {}

}
