import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public set(item: string, data: any) {
    localStorage.setItem(item, JSON.stringify(data));
  }

  public get(item: string) {
    return JSON.parse(localStorage.getItem(item));
  }

  public remove(item: string) {
    localStorage.removeItem(item);
  }

  public removeAll() {
    const id = JSON.parse(localStorage.getItem('Unique_IdDevice'));
    localStorage.clear();
    localStorage.setItem('Unique_IdDevice', JSON.stringify(id))
  }

}
