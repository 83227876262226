import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsultasService } from '../../../services/consultas.service';
import { ComunicationObserverService } from '../../../services/comunication-observer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-call-me',
  templateUrl: './call-me.page.html',
  styleUrls: ['./call-me.page.scss'],
})
export class CallMePage implements OnInit {
  @Input() DataClient: any;
  @Input() fromClubFibex: boolean = false;

  CallForm: FormGroup

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private _consultas: ConsultasService,
    private alert: AlertController,
    private comunicationService: ComunicationObserverService,
    private router: Router
  ) {
    this.CallForm = this.formBuilder.group({
      'Phone': ['', [Validators.required, Validators.minLength(6)]]
    })
    this.fromClubFibex = (router.url.includes('club-fibex'))// if url is from Club Fibex
  }

  get PhoneClient() { return this.CallForm.get('Phone').value }

  ngOnInit() {
    console.log(this.DataClient)
    console.log('Is from club Fibex', this.fromClubFibex)
  }

  Call() {
    try {

      const DataNotif = {
        tipo: 'notif',
        accion: this.fromClubFibex ? 'llamar ClubFibex' : 'llamar',
        PhoneClient: this.PhoneClient,
        cedula: this.DataClient.cedula
      }

      this._consultas.SendMsgwhatsapp(DataNotif).then((Res: any) => {
        if(Res.status) {
          this.closeModal();
          this.comunicationService.disabledBtn$.emit(Res.status)
        }

        // ! verificar el objetivo de esta condicional
        // if (!this.DataClient.phonenumber.includes(this.PhoneClient)) {

        //   this._consultas.SendMsgwhatsapp(DataNotif).then((Res: any) => {

        //     this.createAlert('Solicitud enviada', 'Su solicitud de cambio de correo ha sido enviada')

        //   })

        // } else {
        //   this.createAlert('Solicitud enviada', 'Su solicitud de cambio de correo ha sido enviada')
        // }

      })

    } catch (error) {
      console.error(error)
    }
  }

  async createAlert(Header: any, Msg: any) {

    const alert = await this.alert.create({
      header: Header,
      message: Msg,
      cssClass: 'secondary cust',
      buttons: [
        {
          text: 'Cerrar',
          handler: async (data) => {
            this.closeModal()
          }
        },
      ]
    });
    alert.present();

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
