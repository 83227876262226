<ion-header>
  <ion-toolbar color="primary">
    <ion-title>{{Header}}</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>


<ion-content class="animated fadeIn">

  <ion-card style="margin-top: 10%;">

    <ion-card-header color="primary" *ngIf="SubHeader">
      <ion-card-title style="text-align: center;">{{SubHeader}}</ion-card-title>
    </ion-card-header>

    <ion-card-content>

      <ion-item style="margin-top: 5%; margin-bottom: 5%;">
        <ion-text>{{Content}}</ion-text>
      </ion-item>


      <form [formGroup]="PinForm" class="list-form">
        <ion-item class="ion-no-padding animated fadeInUp">
          <ion-label position="floating" color="primary">Introduzca Pin:</ion-label>
          <ion-input type="text" formControlName="Pin" required></ion-input>
        </ion-item>
        <ion-text *ngIf="PinError" color="danger">El pin ingresado es incorrecto</ion-text>
        <ion-row>
          <ion-col>
            <ion-button icon-left size="full" shape="round" color="primary" (click)="VerifyPin()" tappable
              [disabled]="!PinForm.valid">
              Verificar
            </ion-button>
          </ion-col>
        </ion-row>
      </form>



    </ion-card-content>

  </ion-card>

  <!-- <div class="auth-content" id="modaltype">

    <form [formGroup]="pinValidationForm" class="list-form">
      <ion-item class="ion-no-padding animated fadeInUp">
        <ion-label position="floating" color="light">
          Introduzca Pin:
        </ion-label>
        <ion-input color="secondary" type="text" formControlName="pinValue" color="light"></ion-input>
      </ion-item>
      <div class="ion-margin-top">
        <ion-button icon-left size="medium" expand="full" shape="round" color="dark" (click)="getPin()" tappable
          [disabled]="!pinValidationForm.valid">
          Verificar
        </ion-button>
      </div>
    </form>
  </div> -->
</ion-content>