import { Injectable, EventEmitter, Output, OnInit } from '@angular/core';
import { of, Observable } from 'rxjs'
import { execute } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import gql from "graphql-tag";
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from '../../services/local-storage.service';

export const SUBSCRIBE_CONTROL_REMOTO = gql`
subscription($Code:String){
  ControlPlay(Data:{
    Codigo:$Code
  }){
    Codigo
    Cedula
     Comando
    Url
    Timer
  }
}
`

@Injectable()
export class SuscipcionRemotoService {

  wsurl = environment.ApiBackendFp
  wsurlsub = environment.ApiBackendFp_Sus
  getWsClient: any;

  constructor() { }

  InitSuscriptionControlRemoto(Code: any) {
    try {

      const Datos = {
        Code: Code
      }

      this.createSubscriptionObservable(SUBSCRIBE_CONTROL_REMOTO, Datos).subscribe((eventData: any) => {
        const DataComando = eventData.data.ControlPlay
      })

    } catch (error) {
      console.error(error)
    }
  }

  createSubscriptionObservable(query: any, variables?: any) {
    try {
      //Me conecto al web socket
      this.getWsClient = function (Wsocket: any) {
        if (this.client != undefined) {
          return this.client
        } else {
          this.client = new SubscriptionClient(
            Wsocket, { reconnect: true },
          );
          return this.client;
        }
      }
      if (variables == undefined) {
        const link = new WebSocketLink(this.getWsClient(this.wsurlsub));
        return execute(link, { query: query });
      }
      else {
        const link = new WebSocketLink(this.getWsClient(this.wsurlsub));
        return execute(link, { query: query, variables: variables });
      }
    } catch (error) {
      console.error(error)
    }
  }

}
