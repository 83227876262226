import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../../services/helper.service';
import { RoutingAppService } from '../../../services/routing-app.service';

@Component({
  selector: 'app-btns-bottom',
  templateUrl: './btns-bottom.component.html',
  styleUrls: ['./btns-bottom.component.scss'],
})
export class BtnsBottomComponent implements OnInit {

  public showScrollArrow: boolean = false;

  constructor(
    private helperService: HelperService,
    private _routing: RoutingAppService,
  ) { }

  ngOnInit() {}

  goToChat() {
    this._routing.ChangeRuta("chatbot");
  }
}
