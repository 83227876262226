import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { BehaviorSubject, fromEvent, merge, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { mapTo } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { LogErrorsService } from './log-errors.service';
import { SeguridadDatos } from './bscript.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@awesome-cordova-plugins/network/ngx/index";
import * as i3 from "@angular/common/http";
import * as i4 from "./local-storage.service";
import * as i5 from "./log-errors.service";
import * as i6 from "./bscript.service";
export class VerifyInternetStatusService {
    constructor(alertCtrl, network, platform, http, _localStorageService, _logs, security) {
        this.alertCtrl = alertCtrl;
        this.network = network;
        this.platform = platform;
        this.http = http;
        this._localStorageService = _localStorageService;
        this._logs = _logs;
        this.security = security;
        this.onLine = true;
        this.internetState = new EventEmitter();
        this.online = null;
        this.hasConnection = new BehaviorSubject(false);
        this.onLine = true;
        this.DataUser = this._localStorageService.get('user');
        if (this.platform.is('cordova')) {
            // on Device
            this.network.onConnect().subscribe(() => {
                this.hasConnection.next(true);
                return;
            });
            this.network.onDisconnect().subscribe(() => {
                this.hasConnection.next(false);
                return;
            });
        }
        else {
            // on Browser
            this.online = merge(of(navigator.onLine), fromEvent(window, 'online').pipe(mapTo(true)), fromEvent(window, 'offline').pipe(mapTo(false)));
            this.online.subscribe((isOnline) => {
                if (isOnline) {
                    this.hasConnection.next(true);
                }
                else {
                    this.hasConnection.next(false);
                }
            });
        }
        this.testNetworkConnection();
    }
    initService() {
        try {
            window.addEventListener('online', (event) => this.updateOnlineStatus());
            window.addEventListener('offline', (event) => this.updateOnlineStatus());
        }
        catch (error) {
            this._logs.insertarError(error.message);
        }
    }
    initPhoneNetworkService() {
        this.network.onDisconnect().subscribe(() => {
            this.onLine = false;
            this.internetState.emit(false);
        });
        this.network.onConnect().subscribe(() => {
            this.onLine = true;
            this.internetState.emit(true);
        });
    }
    updateOnlineStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.onLine = navigator.onLine;
            this.internetState.emit(navigator.onLine);
        });
    }
    verifyConection(isOnline, goBack = false) {
        if (!isOnline) {
            this.displayAlert();
        }
    }
    displayAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertCtrl.create({
                header: 'No tiene internet',
                message: 'Conectese a internet para poder usar las funciones de la Aplicación.',
                buttons: [
                    {
                        text: 'Ok',
                        role: 'cancel',
                        cssClass: 'secondary',
                    }
                ]
            });
            yield alert.present();
        });
    }
    getNetworkType() {
        return this.network.type;
    }
    getNetworkStatus() {
        return this.hasConnection.asObservable();
    }
    getNetworkTestThomas() {
        // Encabezados de la petición
        const headers = {
            directory: this.security.encrypt("SAE"),
            method: this.security.encrypt("ActualizarDatos"),
            token: this.security.encrypt(environment.TokenUpdate),
            platform: this.security.encrypt("App")
        };
        return this.http.get(environment.URLApi, { headers });
    }
    getNetworkTestIP() {
        return this.http.get(`https://api.ipify.org?format=json`);
    }
    testNetworkConnection() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.getNetworkTestThomas().subscribe(success => {
                    this.hasConnection.next(true);
                    this.internetState.emit(true);
                    return;
                }, error => {
                    this.getNetworkTestIP().subscribe((response) => {
                        this.hasConnection.next(true);
                        this.internetState.emit(true);
                        return;
                    }, (error) => {
                        this._logs.insertarError(error.message);
                        this.hasConnection.next(false);
                        this.internetState.emit(false);
                        return;
                    });
                });
            }
            catch (error) {
                this._logs.insertarError(error.message);
                this.hasConnection.next(false);
                return;
            }
        });
    }
}
VerifyInternetStatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VerifyInternetStatusService_Factory() { return new VerifyInternetStatusService(i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i2.Network), i0.ɵɵinject(i1.Platform), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.LocalStorageService), i0.ɵɵinject(i5.LogErrorsService), i0.ɵɵinject(i6.SeguridadDatos)); }, token: VerifyInternetStatusService, providedIn: "root" });
