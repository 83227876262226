/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alert-banner.component";
var styles_AlertBannerComponent = [i0.styles];
var RenderType_AlertBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertBannerComponent, data: {} });
export { RenderType_AlertBannerComponent as RenderType_AlertBannerComponent };
export function View_AlertBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["src", "../../../../../assets/img/upgrade-img/alertBanner.gif"], ["width", ""]], null, null, null, null, null))], null, null); }
export function View_AlertBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-banner", [], null, null, null, View_AlertBannerComponent_0, RenderType_AlertBannerComponent)), i1.ɵdid(1, 114688, null, 0, i2.AlertBannerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertBannerComponentNgFactory = i1.ɵccf("app-alert-banner", i2.AlertBannerComponent, View_AlertBannerComponent_Host_0, {}, {}, []);
export { AlertBannerComponentNgFactory as AlertBannerComponentNgFactory };
