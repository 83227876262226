import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dictionary'
})
export class DictionaryPipe implements PipeTransform {

  transform(value: any[], ...args: any[]): any {
    if( args.length === 0 || args === undefined ) value;
    
    if(value.includes('ffth') || value.includes('FTTH') || value.includes('fibra') || value.includes('FIBRA')) {
      return 'FTTH'
    };

    if(value.includes('fibexplay') || value.includes('FIBEXPLAY') || value.includes('FIBEX PLAY')) {
      return 'FibexPlay'
    };

    if(value.includes('dedicado') || value.includes('DEDICADO') || value.includes('Dedicado')) {
      return 'Dedicado'
    };

    return value;
  }

}
