import { Router } from '@angular/router';
import { environment } from '../../../environments/environment.prod';
import { RoutingAppService } from '../routing-app.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../routing-app.service";
export class KioskoGuard {
    constructor(router, _routing) {
        this.router = router;
        this._routing = _routing;
    }
    canActivate(next, state) {
        const isKioskoApp = environment.kioskoApp;
        if (isKioskoApp) {
            this._routing.ChangeRuta('/new-login');
            return false;
        }
        return true;
    }
}
KioskoGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KioskoGuard_Factory() { return new KioskoGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.RoutingAppService)); }, token: KioskoGuard, providedIn: "root" });
