import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { UrlPipe } from './url.pipe';
import { DictionaryPipe } from './dictionary.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { NumberPipe } from './number.pipe';


const custom_pipes = [
  DictionaryPipe,
  UrlPipe,
  SanitizePipe,
  NumberPipe
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule.forChild()
  ],
  declarations: [...custom_pipes],
  exports: [...custom_pipes]
})
export class PipesModule {}
