<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Seleccione una cuenta123</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon> 
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

</ion-header>

<ion-content class="animated fadeIn">
<!--recent places-->
<!-- <ion-item color="secondary"  tappable >
  <ion-icon name="ios-list-box" slot="start" color="light"></ion-icon>
  <ion-text color="light"><strong>{{ RecentCedula.Cedula }}</strong></ion-text>
</ion-item> -->
<!--nearby places-->
  <ion-item *ngFor="let item of cedulas" tappable (click)="elegirCuenta(item)">
    <ion-icon name="ios-list-box" slot="start" color="primary"></ion-icon>
    <ion-text color="primary">{{ item.identidad }}</ion-text>
  </ion-item>
</ion-content>
