export const chatbot = [
  {message: `¿En qué puedo ayudarte hoy?`},
  {message: `Es un programa exclusivo para clientes Fibex Telecom para disfrutar de múltiples beneficios junto a nuestras marcas aliadas a nivel nacional.`},
  {message: `Si eres cliente Fibex, ya eres parte.`},
  {message: `•	Promociones, descuentos, servicios y diversión en categorías como entretenimiento, turismo y más.`},
  {message: `Si estás interesado en ofrecer tus productos en Club Fibex contáctanos al correo electrónico <strong>infoclub@fibextelecom.net</strong>`},
  {message: `
  Descarga la <strong> App Oficina Móvil Fibex, </strong> \ndisponible en <strong> Google Play Store </strong> y <strong>App Store</strong>.
    Accede a <a href="https://oficinamovil.fibextelecom.net/home" target="_blank">[Fibex Oficina Móvil]</a> con tu usuario y contraseña.    
    Selecciona en el menú la ⭐
    <strong> \n¡Y Disfruta de todos los beneficios que te ofrecemos!</strong>
  `}
]