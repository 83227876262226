import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/user/auth.guard';
import { KioskoGuard } from './services/appGuard/kiosko.guard';
import { HomeGuard } from './services/homeGuard/home.guard';

const routes: Routes = [
  {
    path: '', loadChildren: () => import('./pages/walkthrough/walkthrough.module').then(m => m.WalkthroughPageModule), canActivate: [KioskoGuard, AuthGuard],
  },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule), canActivate:[HomeGuard]},
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule), canActivate: [AuthGuard] },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule), canActivate: [AuthGuard] },
  { path: 'edit-profile', loadChildren: () => import('./pages/edit-profile/edit-profile.module').then(m => m.EditProfilePageModule) },
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule) },
  { path: 'hotel-list', loadChildren: () => import('./pages/hotel-list/hotel-list.module').then(m => m.HotelListPageModule) },
  { path: 'hotel-detail/:id', loadChildren: () => import('./pages/hotel-detail/hotel-detail.module').then(m => m.HotelDetailPageModule) },
  { path: 'hotel-checkout/:hotelID/:roomID', loadChildren: () => import('./pages/hotel-checkout/hotel-checkout.module').then(m => m.HotelCheckoutPageModule) },
  { path: 'booking-list', loadChildren: () => import('./pages/booking-list/booking-list.module').then(m => m.BookingListPageModule) },
  { path: 'favorites', loadChildren: () => import('./pages/favorites/favorites.module').then(m => m.FavoritesPageModule) },
  { path: 'local-weather', loadChildren: () => import('./pages/local-weather/local-weather.module').then(m => m.LocalWeatherPageModule) },
  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule) },
  { path: 'support', loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule) },
  { path: 'messages', loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule) },
  { path: 'message/:id', loadChildren: () => import('./pages/message/message.module').then(m => m.MessagePageModule) },
  { path: 'rentcar', loadChildren: () => import('./pages/rentcar/rentcar.module').then(m => m.RentcarPageModule) },
  { path: 'location', loadChildren: () => import('./pages/modal/location/location.module').then(m => m.LocationPageModule) },
  { path: 'ListCedula', loadChildren: () => import('./pages/modal/list-cedula/list-cedula.module').then(m => m.ListCedulaPageModule) },
  { path: 'UpgrateServi', loadChildren: () => import('./pages/modal/upgrate-service/upgrate-service.module').then(m => m.UpgrateServicePageModule) },
  { path: 'activities', loadChildren: () => import('./pages/activities/activities.module').then(m => m.ActivitiesPageModule) },
  { path: 'activity-list', loadChildren: () => import('./pages/activity-list/activity-list.module').then(m => m.ActivityListPageModule) },
  { path: 'activity-detail/:id', loadChildren: () => import('./pages/activity-detail/activity-detail.module').then(m => m.ActivityDetailPageModule) },
  { path: 'activity-checkout/:tripID', loadChildren: () => import('./pages/activity-checkout/activity-checkout.module').then(m => m.ActivityCheckoutPageModule) },
  { path: 'factura', loadChildren: () => import('./pages/factura/factura.module').then(m => m.FacturaModule)},
  { path: 'wifi', loadChildren: () => import('./pages/wifi-password/wifi-password.module').then(m => m.WifiPasswordPageModule)},
  { path: 'NewUpdate', loadChildren: () => import('./pages/new-update/new-update.module').then(m => m.NewUpdatePageModule) },
  { path: 'speedtest', loadChildren: () => import('./pages/speedtest/speedtest.module').then(m => m.SpeedtestPageModule) },
  { path: 'iframe-renderer', loadChildren: () => import('./pages/modal/iframe-renderer/iframe-renderer.module').then(m => m.IframeRendererPageModule) },
  {
    path: 'add-cedula',
    loadChildren: () => import('./pages/add-cedula/add-cedula.module').then(m => m.AddCedulaPageModule)
  },
  {
    path: 'services',
    loadChildren: () => import('./pages/modal/show-services/show-services.module').then(m => m.ShowServicesPageModule)
  },
  {
    path: 'edit-cedula',
    loadChildren: () => import('./pages/edit-cedula/edit-cedula.module').then(m => m.EditCedulaPageModule)
  },
  {
    path: 'create-cliente',
    loadChildren: () => import('./pages/create-cliente/create-cliente.module').then(m => m.CreateClientePageModule)
  },
  {
    path: 'video-player',
    loadChildren: () => import('./pages/modal/video-player/video-player.module').then(m => m.VideoPlayerPageModule)
  },
  {
    path: 'contrato',
    loadChildren: () => import('./pages/contrato/contrato.module').then(m => m.ContratoPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule)
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationPageModule)
  },
  {
    path: 'view-notify',
    loadChildren: () => import('./pages/view-notify/view-notify.module').then( m => m.ViewNotifyPageModule)
  },
  {
    path: 'suport-modal',
    loadChildren: () => import('./pages/modal/suport-modal/suport-modal.module').then(m => m.SuportModalPageModule)
  },
  {
    path: 'reporte-averia-modal',
    loadChildren: () => import('./pages/modal/reporte-averia-modal/reporte-averia-modal.module').then(m => m.ReporteAveriaModalPageModule)
  },
  {
    path: 'notification-modal',
    loadChildren: () => import('./pages/modal/notification-modal/notification-modal.module').then(m => m.NotificationModalPageModule)
  },
  {
    path: 'promo',
    loadChildren: () => import('./pages/promo/promo.module').then(m => m.PromoPageModule)
  },
  {
    path: 'pin-registration',
    loadChildren: () => import('./pages/modal/pin-registration/pin-registration.module').then(m => m.PinRegistrationPageModule)
  },
  {
    path: 'pin-validation',
    loadChildren: () => import('./pages/modal/pin-validation/pin-validation.module').then(m => m.PinValidationPageModule)
  },
  {
    path: 'grilla-programacion',
    loadChildren: () => import('./pages/modal/grilla-programacion/grilla-programacion.module').then(m => m.GrillaProgramacionPageModule)
  },
  {
    path: 'contratar-fibex-play',
    loadChildren: () => import('./pages/modal/contratar-fibex-play/contratar-fibex-play.module').then(m => m.ContratarFibexPlayPageModule)
  },
  {
    path: 'referidos',
    loadChildren: () => import('./pages/referidos/referidos.module').then(m => m.ReferidosPageModule)
  },
  {
    path: 'mi-equipo',
    loadChildren: () => import('./pages/mi-equipo/mi-equipo.module').then(m => m.MiEquipoPageModule)
  },
  {
    path: 'list-cuenta',
    loadChildren: () => import('./pages/modal/list-cuenta/list-cuenta.module').then(m => m.ListCuentaPageModule)
  },
  {
    path: 'propaganda',
    loadChildren: () => import('./pages/modal/propaganda/propaganda.module').then(m => m.PropagandaPageModule)
  },
  {
    path: 'manuales',
    loadChildren: () => import('./pages/manuales/manuales.module').then(m => m.ManualesPageModule)
  },
  {
    path: 'pin-master',
    loadChildren: () => import('./pages/modal/pin-master/pin-master.module').then(m => m.PinMasterPageModule)
  },
  {
    path: 'new-login',
    loadChildren: () => import('./pages/new-login/new-login.module').then(m => m.NewLoginPageModule)
  },
  {
    path: 'control-fibex',
    loadChildren: () => import('./pages/modal/control-fibex/control-fibex.module').then(m => m.ControlFibexPageModule)
  },
  {
    path: 'list-control-fibex',
    loadChildren: () => import('./pages/modal/list-control-fibex/list-control-fibex.module').then(m => m.ListControlFibexPageModule)
  },
  {
    path: 'fibex-remote-control',
    loadChildren: () => import('./pages/ctrlremote/ctrlremote.module').then(m => m.CtrlremoteModule)
  },
  {
    path: 'planes',
    loadChildren: () => import('./pages/planes/planes.module').then(m => m.PlanesPageModule)
  },
  {
    path: 'call-me',
    loadChildren: () => import('./pages/modal/call-me/call-me.module').then(m => m.CallMePageModule)
  },
  {
    path: 'new-register',
    loadChildren: () => import('./pages/new-register/new-register.module').then(m => m.NewRegisterPageModule)
  },

  //Until finish this section
  {
    path: 'club-fibex',
    loadChildren: () => import('./pages/fibex-club/club-tabs/club-tabs.module').then(m => m.ClubTabsModule),
    canActivate:[HomeGuard]
  },

  //this path will be render
  // {
  //   path: 'club-fibex',
  //   loadChildren: () => import('./pages/auxiliar/auxiliar.module').then(m => m.AuxiliarPageModule)
  // },
  {
    path: 'club-fibex/product-details/:id/:showCoupon',
    loadChildren: () => import('./pages/fibex-club/product-details/product-details.module').then(m => m.ProductDetailsModule),
    pathMatch: 'full',
    
  },
  {
    path: 'security',
    loadChildren: () => import('./pages/security/security.module').then(m => m.SecurityPageModule)
  },
  {
    path: 'auxiliar',
    loadChildren: () => import('./pages/auxiliar/auxiliar.module').then( m => m.AuxiliarPageModule)
  },
  {
    path: 'push-message',
    loadChildren: () => import('./pages/push-message/push-message.module').then( m => m.PushMessagePageModule)
  },
  {
    path: 'speedtest',
    loadChildren: () => import('./pages/speedtest/speedtest.module').then( m => m.SpeedtestPageModule)
  },
  {
    path: 'general-information',
    loadChildren: () => import('./pages/modal/general-information/general-information.module').then( m => m.GeneralInformationPageModule)
  },
  {
    path: 'iframe-renderer',
    loadChildren: () => import('./pages/modal/iframe-renderer/iframe-renderer.module').then( m => m.IframeRendererPageModule)
  },
  {
    path: 'recommend-and-win',
    loadChildren: () => import('./pages/recommend-and-win/recommend-and-win.module').then( m => m.RecommendAndWinPageModule)
  },
  {
    path: 'club-fibex/club-iframe',
    pathMatch: 'full',
    loadChildren: () => import('./pages/fibex-club/club-iframe/club-iframe.module').then( m => m.ClubIframePageModule)
  },
  {
    path: 'club-fibex/company-profile/:companyId',
    pathMatch: 'full',
    loadChildren: () => import('./pages/fibex-club/company-profile/company-profile.module').then( m => m.CompanyProfilePageModule)
  },
  {
    path: '**', redirectTo: 'home'
  },




];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
