import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { CallMePage } from '../../../pages/modal/call-me/call-me.page';
import { ModalController } from '@ionic/angular';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { Router } from '@angular/router';
import { RoutingAppService } from '../../../services/routing-app.service';
import { FilterComponent } from '../../../components/filter/filter.page';
import { SubscriptionGraphqlService } from '../../../services/subscription-graphql.service';
export class ClubHeaderComponent {
    constructor(modalCtrl, router, athentication, _SubscriptionGraphqlService, _routing) {
        this.modalCtrl = modalCtrl;
        this.router = router;
        this.athentication = athentication;
        this._SubscriptionGraphqlService = _SubscriptionGraphqlService;
        this._routing = _routing;
        this.showSearchBar = false;
        this.showFilterBtn = false;
        this.showLightMode = false;
        this.navigateToHome = new EventEmitter();
        this.goToSearchEmit = new EventEmitter();
        this.handleSearchEmit = new EventEmitter();
        this.NewMsg = false;
        this.user = this.athentication.getUser();
    }
    ngOnInit() {
        this._SubscriptionGraphqlService.chatState.subscribe((data) => {
            this.NewMsg = true;
        });
    }
    callClient() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalCtrl.create({
                component: CallMePage,
                componentProps: { DataClient: { cedula: this.user.identidad }, fromClubFibex: true },
                mode: 'ios'
            });
            yield modal.present();
        });
    }
    goToClubIframe() {
        this.router.navigate(['club-fibex', 'club-iframe']);
    }
    navegateHome() {
        this.navigateToHome.emit();
    }
    openFilterModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalCtrl.create({
                component: FilterComponent,
                cssClass: 'full-screen-modal'
            });
            modal.present();
        });
    }
    goToSearch() {
        this.goToSearchEmit.emit();
    }
    handleSearch(event) {
        this.handleSearchEmit.emit(event);
    }
    goToChat() {
        this.NewMsg = false;
        this._routing.ChangeRuta("chat");
    }
}
