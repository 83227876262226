import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { SubscriptionGraphqlService } from "../../services/subscription-graphql.service";
import { LocalStorageService } from '../../services/local-storage.service';
import { VerifyInternetStatusService } from '../../services/verify-internet-status.service';
import { EstadisticasService } from '../../services/estadisticas.service';
import { environment } from '../../../environments/environment.prod';
import { FormBuilder, Validators } from '@angular/forms';
import { ConsultaPagoMovilService } from '../../services/consulta-pago-movil.service';
import { RoutingAppService } from '../../services/routing-app.service';
export class FavoritesPage {
    constructor(_routing, _SubscriptionGraphqlService, _localStorageService, _internetService, _estadisticas, _formBuilder, _alert, _consultaPagoMovil, modalCtrl) {
        this._routing = _routing;
        this._SubscriptionGraphqlService = _SubscriptionGraphqlService;
        this._localStorageService = _localStorageService;
        this._internetService = _internetService;
        this._estadisticas = _estadisticas;
        this._formBuilder = _formBuilder;
        this._alert = _alert;
        this._consultaPagoMovil = _consultaPagoMovil;
        this.modalCtrl = modalCtrl;
        this.onLine = true;
        this.urlPagos = 'https://pagos.fibextelecom.net/?app=&dni=';
        this.secureUrl = '';
        this.permitirPagoMovil = environment.permitirPagoMovil;
        this.transferencia = false;
        this.pagoMovil = false;
        this.pin = null;
        this.pagado = false;
        this.success = false;
        this.failure = false;
        this.fecha = "";
        this.data = null;
        this.bancos = [];
        let emited = false;
        this._internetService.testNetworkConnection();
        this.onLine = this._internetService.onLine;
        this._internetService.internetState.subscribe((data) => {
            if (!emited) {
                this.onLine = data;
                if (!this.onLine) {
                    this._internetService.verifyConection(this.onLine, true);
                    this._routing.ChangeRuta('home');
                }
            }
            emited = true;
        });
    }
    ngOnInit() {
        // this._internetService.verifyConection(this.onLine, true);
        this.onPagoMovilForm = this._formBuilder.group({
            'numero_telefono': [null, Validators.compose([
                    Validators.required,
                ])],
            'banco': [null, Validators.compose([
                    Validators.required,
                ])],
            'monto': [null, Validators.compose([
                    Validators.required,
                ])]
        });
        this.CedulaActual = this._localStorageService.get('CedulaActual');
        this.secureUrl = environment.kioskoApp ? `${this.urlPagos}${this.CedulaActual.identidad}&&kiosco=true` : `${this.urlPagos}${this.CedulaActual.identidad}`;
        console.log(this.secureUrl);
        if (this.permitirPagoMovil) {
            //this.getBancosNacionales(); Solo se va a usar Mercantil temporalmente
            this.bancos = [{ nomBco: 'Mercantil' }];
        }
    }
    getBancosNacionales() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const allBancos = yield this._consultaPagoMovil.consultaBancos();
                const nacionales = allBancos.error ? [] : allBancos.data.filter((item) => item.nacional === 1);
                this.bancos = nacionales;
            }
            catch (error) {
                this.bancos = [];
            }
        });
    }
    editprofile() {
        this._routing.ChangeRuta('edit-profile');
    }
    seleccionarPagoMovil() {
        this.transferencia = false;
        this.pagoMovil = true;
    }
    seleccionarTransferencia() {
        this.transferencia = true;
        this.pagoMovil = false;
    }
    procesarPago() {
        this.fecha = new Date().toLocaleString();
        this.data = this.onPagoMovilForm.value;
        if (true) {
            this.pagado = true;
            this.failure = false;
            this.success = true;
        }
        else {
            this.pagado = true;
            this.success = false;
            this.failure = true;
        }
        this.onPagoMovilForm.reset();
    }
    createAlert(header, message = "") {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this._alert.create({
                header,
                message,
                buttons: [
                    {
                        text: 'Cerrar',
                        role: 'Cancelar',
                        cssClass: 'secondary',
                    }
                ]
            });
            alert.present();
        });
    }
    solicitarPin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let message = "Por favor coloque el código que le fue enviado a su correo electrónico";
            let alert = this._alert.create({
                header: "Clave de pago",
                message,
                inputs: [
                    {
                        name: 'Confirmacion',
                        placeholder: 'Clave de confirmación'
                    }
                ],
                buttons: [
                    {
                        text: 'Cerrar',
                        role: 'cancel',
                        handler: data => {
                        }
                    },
                    {
                        text: 'Aceptar',
                        handler: data => {
                            if (data.Confirmacion) {
                                // logged in!
                            }
                            else {
                                // invalid login
                                return false;
                            }
                        }
                    }
                ]
            });
            (yield alert).present();
            this.pin = "vzkjxcpasoi41-04291-dfka;d";
        });
    }
}
