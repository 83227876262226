<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Datos de {{currentMethod}}</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon> 
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="animated fadeIn">
  <div class="auth-content">

    <form *ngIf="currentMethod==='Paypal'" [formGroup]="paypalForm" class="list-form">
      <ion-item  class="ion-no-padding animated fadeInUp" >
        <ion-label position="floating" color="light">
          Correo Paypal:
        </ion-label>
        <ion-input color="secondary" type="text" formControlName="correoPayPal" color="light"></ion-input>
      </ion-item>
      <div class="ion-margin-top">
        <ion-button icon-left size="medium" expand="full" shape="round" color="dark" (click)="closeModal()" [disabled]="!paypalForm.valid"
          tappable>
          Guardar datos
        </ion-button>
      </div>
    </form>
  
    <form *ngIf="currentMethod!=='Paypal'" [formGroup]="creditCardFrom" class="list-form">
      <ion-item  class="ion-no-padding animated fadeInUp" >
        <ion-label position="floating" color="light">
          Num de tarjeta:
        </ion-label>
        <ion-input color="secondary" type="text" formControlName="numTarjeta" color="light"></ion-input>
      </ion-item>
      <ion-item class="ion-no-padding animated fadeInUp" >
        <ion-label position="floating" color="light">
          Nombre del Titular:
        </ion-label>
        <ion-input color="secondary" type="text" formControlName="fullName" color="light"></ion-input>
      </ion-item>
      <ion-item class="ion-no-padding animated fadeInUp" >
        <ion-label position="top" color="light">
          Expiración:
        </ion-label>
        <ion-input color="secondary" type="date"  formControlName="Fecha" color="light"></ion-input>
        
      </ion-item>
      <ion-item class="ion-no-padding animated fadeInUp" >
        <ion-label position="floating" color="light">
          CVC:
        </ion-label>
        <ion-input color="secondary" type="text" formControlName="CVC" color="light"></ion-input>
      </ion-item>
      <div class="ion-margin-top">
        <ion-button icon-left size="medium" expand="full" shape="round" color="dark" (click)="closeModal()"
          tappable [disabled]="!creditCardFrom.valid">
          Guardar datos
        </ion-button>
      </div>
    </form>
  </div>
</ion-content>