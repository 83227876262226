import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { AlertController, IonSelect } from '@ionic/angular';
import { WebsocketAPI } from '../../../../app/lib/websocket.api';
import { environment } from '../../../../environments/environment';
import { HelperService } from '../../../services/helper.service';
import { LocalStorageService } from '../../../services/local-storage.service';



@Component({
  selector: 'app-modal-show-qr',
  templateUrl: './modal-show-qr.component.html',
  styleUrls: ['./modal-show-qr.component.scss']
})
export class ModalShowQrComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('branchOfficeSelector', { static: false }) branchOfficeSelector: IonSelect;
  
  @Input() cuponname: string | null = null;
  @Input() imgproduct: string | null = null;
  @Input() qrcode: string | null = null;
  @Input() qrimage: string | null = null;
  @Input() companyName?: string;
  @Input() companyLogo?: string;
  @Input() couponCount: number = 0;
  //!👇👇 please work with its types
  @Input() branchOffices: any; // TODO NO ESTA BIEN EL ANY
  @Input() isQRListView: boolean = false;
  @Input() alreadyGenerated: boolean = false;

  @Output() generateQR: EventEmitter<void> = new EventEmitter();
  @Output() closeButton: EventEmitter<void> = new EventEmitter();
  @Output() acceptButton: EventEmitter<void> = new EventEmitter();
  @Output() acceptCuponSuccessed: EventEmitter<void> = new EventEmitter();
  @Output() setCurrentBranch: EventEmitter<any> = new EventEmitter();


  private socket: WebsocketAPI | null = null;
  public succesedCupon: boolean = false;
  userData = this.localStorage.get('CedulaActual')
  
  
  constructor(private helper: HelperService, private localStorage: LocalStorageService, private alertCtrl: AlertController) { }

  ngOnInit() {
    // console.log("HERE MAN PEDRO FIGUEROA");
    console.log('QR IMAGE')
    console.log(this.qrimage)
    this.FinishWebsocketNotificationQR();

    console.log(this.couponCount)
  }

  ngOnDestroy() {
    this.succesedCupon = false;
    this.FinishWebsocketNotificationQR();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const qrcode = changes["qrcode"];
    if(qrcode && qrcode.previousValue !== qrcode.currentValue) {
      this.FinishWebsocketNotificationQR();
      if(qrcode.currentValue) {
        this.InitWebsocketNotificationQR();
      }
    }
  }
  
  openBranchOfficeSelector(){
    this.branchOfficeSelector.open();
  }


  /**
   * Inicializar servicio para notificar QR
   */
  InitWebsocketNotificationQR() {
    // Iniciar
    if(this.qrcode && !this.socket && !this.succesedCupon) {
      // Crear conexión hacia al servidor
      // console.log("InicializedSocketNotification");
      this.socket = new WebsocketAPI(environment.apiTestWS + "/qr-notify/" + this.qrcode);
      this.socket.onclose = () => {
        // eliminar la variable al cerrar la conexión para permitir la entrada de esta funcion nuevamente
        // console.log("FinishedSocketNotification");
        delete this.socket;
        
        if(this.qrcode) {
          // Reintentar a los 3 segundos
          setTimeout(() => {
            this.InitWebsocketNotificationQR();
          }, 3000)
        }
      }

      // Manejar eventos (actualmente 1)
      this.socket.onmessage = (event: "notify_redeemed_coupon", data: { qr: string }) => {
        switch(event) {
          case "notify_redeemed_coupon":
            // Cupon canejado correctamente (ES NECESARIO ACTUALIZAR)
            // ...
            // alert("CUPON ESCANEADO CON EXITO");
            this.succesedCupon = true;
            
            this.socket.close(); // !!!! IMPORTANTE CERRAR LA CONEXIÓN  AL RECIBIR ESTE EVENTO, PARA QUE NO QUEDE EN EL AIRE
            break;
        }
      }
    }
  }

  FinishWebsocketNotificationQR() {
    if(this.socket) {
      try {
        this.socket.close();
      }
      catch(err) {
        console.error(err);
      }
      delete this.socket;
    }
  }

  async downloadQR(){
    const { identidad } = this.userData
    // this.helper.sendBillsByWhatsApp()
    
    const alertCtrl = await this.alertCtrl.create({
      header: 'Cupón enviado',
      message: 'En breve recibirá en su dispositivo móvil el cupón seleccionado.',
      cssClass: 'secondary cust',
      buttons: [
        {
          text: 'Cerrar',
          role: 'Cancelar',
          cssClass: 'cust',
        }
      ]
    })

    alertCtrl.present()
  }
}
