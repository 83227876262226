import { EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { execute } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import gql from "graphql-tag";
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LogErrorsService } from './log-errors.service';
import * as i0 from "@angular/core";
import * as i1 from "./log-errors.service";
export const SUBSCRIBE_QUERY = gql `
  subscription ($Operador: String) {
    newChat(input: {operador:$Operador}) {
      idEmpresa
      phone
      idConversacion
      idmessage
      timestamp
      me_to
      contentType
      content
      TimeRead
      status
      statusc
      readmsg
      operador
      lic
      tag
      etiqueta
    }
  }
`;
export const SUBSCRIBE_UPDATE_CHATS = gql `
  subscription ($input: String) {
    updateChat(input: $input) {
      idConversacion
      operador
      lic
      phone
    }
  }
`;
export const SUBSCRIBE_OPTIONS_APP = gql `
  subscription{
    OptionApp{
      Op
      Act
      mensaje
      Tipo
      URL
      idDevice
    }
  }
`;
export const SUBSCRIBE_CHAT = gql `
subscription($Cedula:String, $Lic:String){
  newChat(
    tipo:"ChatApp"
    input:{
      phone:$Cedula
      lic:$Lic
    }
    ){
      idEmpresa
      phone
      idConversacion
      idmessage
      timestamp
      me_to
      contentType
      content
      TimeRead
      status
      statusc
      readmsg
      operador
      lic
      tag
      etiqueta
      priority
      DatePc
    }
  }
`;
export class SubscriptionGraphqlService {
    constructor(_logs) {
        this._logs = _logs;
        this.wsurl = environment.ApiGraphQl;
        this.wsurlsub = environment.UrlSubscriptions;
        /*    Token = sessionStorage.getItem('DataLogin') */
        this.Token = "";
        this.OpSelect = new EventEmitter();
        this.OpSelect2 = new EventEmitter();
        this.chatState = new EventEmitter();
        //this.pruebitaChat = of('hola');}
        if (!sessionStorage.getItem('tokenSubscription')) {
            return;
        }
        this.setToken(environment.Token);
        //this.initSubscription();
    }
    GetOptions(op) {
        this.OpSelect.emit(op);
    }
    EmitOPenService() {
        this.OpSelect.emit({ option: "OpenService" });
    }
    setToken(token) {
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);
        //this.idOperador =decodedToken.Data.idUser
        sessionStorage.setItem('tokenSubscription', (true).toString());
    }
    InitSubscriptionChat(cedula, Lic) {
        try {
            const Datos = {
                Cedula: cedula,
                Lic: Lic
            };
            this.createSubscriptionObservable(SUBSCRIBE_CHAT, Datos).subscribe((eventData) => {
                let NewChats = eventData.data.newChat;
                this.chatState.emit(NewChats);
            });
        }
        catch (error) {
            this._logs.insertarError(error.message);
        }
    }
    initSubscription() {
        try {
            this.createSubscriptionObservable(SUBSCRIBE_OPTIONS_APP).subscribe((eventData) => {
                let DataOptions = eventData.data.OptionApp;
                this.GetOptions({ option: DataOptions.Op, Action: DataOptions.Act, mensaje: DataOptions.mensaje });
            });
        }
        catch (error) {
            this._logs.insertarError(error.message);
        }
    }
    createSubscriptionObservable(query, variables) {
        try {
            //Me conecto al web socket
            this.getWsClient = function (Wsocket) {
                if (this.client != undefined) {
                    return this.client;
                }
                else {
                    this.client = new SubscriptionClient(Wsocket, { reconnect: true });
                    return this.client;
                }
            };
            if (variables == undefined) {
                const link = new WebSocketLink(this.getWsClient(this.wsurlsub));
                return execute(link, { query: query });
            }
            else {
                const link = new WebSocketLink(this.getWsClient(this.wsurlsub));
                return execute(link, { query: query, variables: variables });
            }
        }
        catch (error) {
            this._logs.insertarError(error.message);
        }
    }
}
SubscriptionGraphqlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubscriptionGraphqlService_Factory() { return new SubscriptionGraphqlService(i0.ɵɵinject(i1.LogErrorsService)); }, token: SubscriptionGraphqlService, providedIn: "root" });
