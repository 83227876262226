import { from } from 'rxjs';
import { LogErrorsService } from './log-errors.service';
import * as i0 from "@angular/core";
import * as i1 from "./log-errors.service";
export class GetInfoDeviceService {
    constructor(_logs) {
        this._logs = _logs;
        this.url = 'https://checkip.amazonaws.com/';
    }
    getIpFromUser() {
        return from(this.getIPFromAmazon());
    }
    getIPFromAmazon() {
        try {
            return fetch("https://checkip.amazonaws.com/").then(res => res.text());
        }
        catch (error) {
            this._logs.insertarError(error.message);
            return '';
        }
    }
}
GetInfoDeviceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GetInfoDeviceService_Factory() { return new GetInfoDeviceService(i0.ɵɵinject(i1.LogErrorsService)); }, token: GetInfoDeviceService, providedIn: "root" });
