import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { EstadisticasService } from './estadisticas.service';
import { NavigationEnd, NavigationStart, Router, Event } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutingAppService {

  private routerSub: Subscription
  public showSpinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(
    private navCtrl: NavController,
    private _estadistica: EstadisticasService,
    private _router: Router
  ) { }

  ChangeRuta( Ruta: string ) {
    console.log(Ruta)
    
    if(Ruta === 'new-login' || Ruta === '/home') this.navCtrl.navigateRoot( [Ruta], {replaceUrl:true})


    this.navCtrl.navigateForward(Ruta);
    this._estadistica.RegisterAccion(Ruta)
    this._estadistica.RutaActual = Ruta
  }

  //* to set showSpinnerSubject true if the routing is loading | or false if routing is loaded
  public subRouterEvents = (): void => {
    this.routerSub = this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        console.log('router event start',routerEvent)
        this.showSpinnerSubject.next(true);
      }

      if (routerEvent instanceof NavigationEnd) {
        console.log('router event end',routerEvent)
        this.showSpinnerSubject.next(false);
      }
    })
  }

  //* get the value of showSpinnerSubject
  public getShowSpinnerSubject = (): Observable<boolean> => {
    return this.showSpinnerSubject.asObservable()
  }

}
