<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Contratar FibexPlay</ion-title>
    <ion-buttons slot="end">
      <ion-button size="small" shape="round" (click)="closeModal()" color="tertiary">
        <ion-icon slot="start" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-card class="bg-white">
    <ion-card-content class="ion-no-padding">

      <ion-grid fixed class="ion-no-padding">
        <ion-row>
          <ion-col size="12" class="ion-padding">
            <form [formGroup]="formContratar">
              <ion-list class="ion-margin-bottom">
                <!-- <ion-list-header color="light">
                  <ion-label class="fw700">{{ 'app.label.userdata' | translate }}</ion-label>
                </ion-list-header> -->

                <ion-item>
                  <ion-label color="dark" position="stacked">Nombre completo:</ion-label>
                  <ion-input formControlName="fullName" inputmode="text" placeholder="Nombre completo"
                    value={{fullName.value}}></ion-input>
                </ion-item>

                <ion-item>
                  <ion-label color="dark" position="stacked">DNI/RIF:</ion-label>
                  <ion-input formControlName="identidad" inputmode="text" placeholder="Documento de identidad" value={{identidad.value}} ></ion-input>
                </ion-item>


              </ion-list>
            </form>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-button 
      shape="round"
      expand="full"
      background-color="primary"
      class="ion-margin"
      (click)="GetDataForm()">
      Contratar
    </ion-button>

    </ion-card-content>
  </ion-card>


</ion-content>