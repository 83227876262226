import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AlertController, NavController, ModalController, IonContent } from '@ionic/angular';
import { ConsultasService } from '../../../services/consultas.service';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { DateCompareService } from '../../../utilities/date-compare.service';
import { UpgrateServicePage } from '../upgrate-service/upgrate-service.page';
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";
import { LocalStorageService } from '../../../services/local-storage.service';
import { DiccionarioService } from '../../../services/diccionario.service';
import { EstadisticasService } from '../../../services/estadisticas.service';
import { MiEquipoService } from "../../../services/mi-equipo.service";
import { VerifyInternetStatusService } from '../../../services/verify-internet-status.service';
import { Router } from '@angular/router';
import { RoutingAppService } from '../../../services/routing-app.service';
export class ShowServicesPage {
    constructor(modal, fb, consultServices, dateCompare, alertController, userAuth, modalCtrl, _navCtrl, _routing, alert, _SubscriptionGraphqlService, _localStorageService, diccionario, _estadisticas, _miEquipo, _internetService, router) {
        this.modal = modal;
        this.fb = fb;
        this.consultServices = consultServices;
        this.dateCompare = dateCompare;
        this.alertController = alertController;
        this.userAuth = userAuth;
        this.modalCtrl = modalCtrl;
        this._navCtrl = _navCtrl;
        this._routing = _routing;
        this.alert = alert;
        this._SubscriptionGraphqlService = _SubscriptionGraphqlService;
        this._localStorageService = _localStorageService;
        this.diccionario = diccionario;
        this._estadisticas = _estadisticas;
        this._miEquipo = _miEquipo;
        this._internetService = _internetService;
        this.router = router;
        this.dniClient = '';
        this.useModal = false;
        this.infoEquipo = [];
        this.infoPaquetesGreater = [];
        this.numberPattern = new RegExp(/\d+/g);
        this.BtnDisable = false;
        this.OrdenPendiente = false;
        this.regExpRemoveLetters = /[^0-9]+/g;
        this.showScrollArrow = false;
        this.AllServices = [];
        this.AllContratos = [];
        this.DataCeduAct = this._localStorageService.get('CedulaActual');
        this.dateTurbo = this._localStorageService.get('dateTurbo');
        this.countTurbos = this._localStorageService.get('countTurbos');
        this.VerifyOrdenes();
        this._internetService.testNetworkConnection();
        this.onLine = this._internetService.onLine;
        let emited = false;
        this._internetService.internetState.subscribe((data) => {
            if (!emited) {
                this.onLine = data;
                if (!this.onLine) {
                    this._internetService.verifyConection(this.onLine, true);
                    this._routing.ChangeRuta('home');
                }
            }
            emited = true;
        });
    }
    ngOnInit() {
        this.ListServiceTo();
        this._SubscriptionGraphqlService.OpSelect.subscribe((op) => {
            switch (op.option) {
                case "op5-1":
                    this.closeModal();
                    break;
                case "op5-2":
                    this.ModalUpgrade(this.infoPaquetesGreater[0]);
                    break;
            }
        });
        this.formService = this.fb.group({
            serviceSelect: ['']
        });
        this.user = this.userAuth.getUser();
        if (this.DataCeduAct) {
            this.mesualidad = this.DataCeduAct.Mensualidad;
            // this.getInfoEquipoClient(this.DataCeduAct.identidad)
            this.GetAllService(this.DataCeduAct.identidad);
            return;
        }
        else {
            if (this.dniClient.length > 0) {
                // this.getInfoEquipoClient(this.dniClient);
                this.GetAllService(this.dniClient);
                return;
            }
            else if (this.dniClient.length === 0) {
                // this.getInfoEquipoClient(this.user.identidad);
                this.GetAllService(this.user.identidad);
                return;
            }
        }
    }
    get serviceSelect() { return this.formService.get('serviceSelect'); }
    VerifyOrdenes() {
        try {
            this.consultServices.GetHistorialOrdenes(this.DataCeduAct.id_contrato).then((ResOrdenes) => {
                let DateTurboUP = new Date(this._localStorageService.get('SolicitudUpTur'));
                let DateAct = new Date();
                let Encontre = false, i = 0;
                while (i < ResOrdenes.length && Encontre == false) {
                    if (ResOrdenes[i].nombre_tipo_orden === 'UPGRADE SERVICIO POR APP FIBEX' || ResOrdenes[i].nombre_tipo_orden === 'ORDEN DE SERVICIOS') {
                        if (ResOrdenes[i].status_orden != "FINALIZADA") {
                            this.OrdenPendiente = true; //orden pendiente por finalizar
                            this.BtnDisable = true;
                        }
                        else if ((ResOrdenes[i].status_orden === "FINALIZADA")) {
                            this.OrdenPendiente = false; // sin ordenes pendientes 
                        }
                        Encontre = true;
                    }
                    i++;
                }
                if ((`${DateAct.getDate()}/${(DateAct.getMonth() + 1)}` === `${DateTurboUP.getDate()}/${(DateTurboUP.getMonth() + 1)}`) || (this.OrdenPendiente === true)) {
                    this.BtnDisable = true;
                }
                else {
                    this.BtnDisable = false;
                }
            });
        }
        catch (error) {
            console.error(error);
        }
    }
    GetAllService(Cedula) {
        try {
            this.consultServices.InfoServicesClientes(Cedula).then((Res) => {
                console.log('SERVICIOS');
                console.log(Res);
                if (Res.Contratos && Res.Contratos.length > 0) {
                    this.infoEquipo = Res.Contratos;
                    console.log(this.infoEquipo);
                }
            });
        }
        catch (error) {
            console.error(error);
        }
    }
    // getInfoEquipoClient(dni: string) {
    //   this.GetAllService(dni)
    //   this.consultServices.InfoEquiposClientes(dni)
    //     .then(async (res) => {
    //       try {
    //         this.infoEquipo = res;
    //         if (res.length > 0) {
    //           this.service = this.infoEquipo[0];
    //           if (this.service.sistema == "SMARTOLT") this.service.sistema = "FIBRA FTTH"
    //           console.log(this.service)
    //           const speedResponse = await this._miEquipo.getSpeed(this.service.paquetes.replace(";", "").trim());
    //           const speedData = speedResponse.data;
    //           if (speedData) {
    //             this.service.paquetes = speedData;
    //           } else {
    //             this.service.paquetes = this.service.paquetes;
    //           }
    //           this.infoPaquetesGreater = this.filterService(this.service.paquetes)
    //           this.serviceSelect.patchValue(this.service.codigo_es);
    //           return;
    //         }
    //         /* else {
    //           this.AllServices.forEach((element: any) => {
    //             this.infoEquipo.push({
    //               codigo_es: element.id_serv,
    //               id_es: element.id_cont_serv,
    //               nombre_modelo: "MODELO DEFAULT",
    //               paquetes: element.nombre_servicio,
    //               status_es: element.status_con_ser,
    //             })
    //           });
    //         } */
    //         if (this.useModal) {
    //           this.closeModal();
    //         } else {
    //           this._navCtrl.back();
    //         }
    //         this.createAlert();
    //       } catch (error) {
    //       }
    //     })
    // }
    filterService(service) {
        let result = service.match(this.numberPattern);
        if (result) {
            let currentCapacity = result.join('');
            let arrayFilterservice = this.infoPaquetes.filter((paquete) => {
                let capacidad = paquete.Capacidad.match(this.numberPattern);
                if (parseInt(currentCapacity) < parseInt(capacidad)) {
                    if (this.user.tipoCliente === 'Persona Natural') {
                        if (paquete.Categoria === 'HOGAR') {
                            return paquete;
                        }
                    }
                    else if (paquete.Categoria !== 'HOGAR') {
                        return paquete;
                    }
                }
            });
            return arrayFilterservice;
        }
        return [];
    }
    ListServiceTo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            /* let infopack = await this.consultServices.ListService()
            if (infopack) {
              this.infoPaquetes = infopack;
            } */
            this.consultServices.ListService().then((ResService) => {
                this.infoPaquetes = ResService;
            });
            /**
             *
             * json turbo upgrade
          
          {
                  Cedula:"12345678",
                  idDevice:"hkj58we",
                  Accion:"UPGRATE", indica que selecciono el cliente un UPGRATE o un TURBO
                  OldPlan:"10Mb-Hogar", plan actual
                  NewPlan:"30Mb-Hogar", plan nuevo
                  Status:0,
                  Email:"juanlobo@outlook.com"
                }
          
          json SolicitudPermisoTv
          
          {
                    Cedula: "29845112",
                    idDevice: "6464",
                    Canal: "Prueba"
                  }
             */
        });
    }
    servicesSelected(codigo_es) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let services = this.infoEquipo.find((service) => service.codigo_es === codigo_es);
                if (services) {
                    this.service = services;
                    console.log(this.service);
                    if (this.service.sistema == "SMARTOLT")
                        this.service.sistema = "FIBRA FTTH";
                    const speedResponse = yield this._miEquipo.getSpeed(this.service.paquetes.replace(";", "").trim());
                    const speedData = speedResponse.data;
                    if (speedData) {
                        this.service.paquetes = speedData;
                    }
                    else {
                        this.service.paquetes = this.service.paquetes;
                    }
                    this.infoPaquetesGreater = this.filterService(this.service.paquetes);
                    this.serviceSelect.patchValue(this.service.codigo_es);
                }
            }
            catch (error) {
            }
        });
    }
    addTurbo() {
        const DatosServicios = Object.assign({ PlanActual: this.service.paquetes, Accion: 'TURBO' }, this.infoPaquetesGreater[0], this.DataCeduAct, this.service);
        this.dateTurbo = this._localStorageService.get('dateTurbo');
        this.countTurbos = this._localStorageService.get('countTurbos');
        let countTurbosInit;
        let date;
        if (this.dateTurbo === null) {
            countTurbosInit = 0;
            date = parseInt(this.dateTurbo);
            // return;
        }
        else if (this.countTurbos) {
            countTurbosInit = this._localStorageService.get('countTurbos');
            // return;
        }
        if (date !== undefined && !isNaN(date)) {
            // se verifica que sean 24hrs despues de la ultima que se guardo
            // si es asi se aplica un nuevo turbo
            // si no es asi el cliente no puede optar por uno nuevo
            const { hours, minutes, seconds, days } = this.dateCompare.timeDifference(new Date().getTime(), date);
            if (hours >= 24) {
                if (countTurbosInit === 3) {
                    if (new Date().getDate() === 1) {
                        // Es el primer dia del mes
                        countTurbosInit = 0;
                        return;
                    }
                    else {
                        // ya alcanzo el maximo de turbos por mes
                        this.presentAlertMultipleButtons('Turbo', 'Lo sentimos, ya alcanzo el máximo de turbos por mes').then();
                        return;
                    }
                }
                // se aplica un nuevo turbo
                // Consulta API y guarda información 
                if (this.infoPaquetesGreater.length > 0) {
                    this.consultServices.TurBoUpgrade(DatosServicios).then((res) => {
                        let newDate = new Date().getTime();
                        this._localStorageService.set('countTurbos', ++countTurbosInit);
                        this._localStorageService.set('dateTurbo', newDate);
                        this.presentAlertMultipleButtons('Turbo', 'Turbo aplicado con éxito, dipones del doble de tu velocidad por 24h.').then();
                        this.BtnDisable = true;
                        this._localStorageService.set('SolicitudUpTur', new Date());
                    });
                    return;
                }
            }
            // Mensaje indicando que aun no han pasado las 24hrs
            this.presentAlertMultipleButtons('Turbo', 'Lo sentimos, no ha pasado el tiempo necesario (24h) para aplicar un nuevo turbo, debe esperar').then();
            return;
        }
        else {
            // se aplica turbo la primera vez o no teniedo fecha inicial del primer turbo
            if (this.infoPaquetesGreater.length > 0) {
                this.consultServices.TurBoUpgrade(DatosServicios).then((res) => {
                    let firstDate = new Date().getTime();
                    this._localStorageService.set('countTurbos', ++countTurbosInit);
                    this._localStorageService.set('dateTurbo', firstDate);
                    this.BtnDisable = true;
                    this._localStorageService.set('SolicitudUpTur', new Date());
                    this.presentAlertMultipleButtons('Turbo', 'Turbo aplicado con éxito').then();
                });
            }
        }
    }
    createAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alert.create({
                header: 'No tiene servicios disponibles.',
                cssClass: 'secondary cust',
                buttons: [
                    {
                        text: 'Cerrar',
                        role: 'Cancelar',
                        cssClass: 'secondary',
                    },
                ]
            });
            alert.present();
        });
    }
    closeModal() {
        this.modal.dismiss();
    }
    presentAlertMultipleButtons(header, msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                header: header,
                message: msg,
                buttons: ['Aceptar']
            });
            yield alert.present();
        });
    }
    ModalUpgrade(pack) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalCtrl.create({
                component: UpgrateServicePage,
                componentProps: {
                    PlanActual: this.service.paquetes,
                    DatosUser: this.DataCeduAct,
                    DatosEquipo: this.service,
                    pack
                }
            });
            yield modal.present();
            const DataReturn = yield modal.onWillDismiss();
            this.BtnDisable = DataReturn.data;
        });
    }
    goToPlanes() {
        this._routing.ChangeRuta('./planes');
    }
    navigateTo(ruta) {
        this._routing.ChangeRuta(ruta);
    }
    filterData(value) {
        let regExp = /(FIBRA OPTICA|FIBEXPLAY)/;
        let valueMatch = value.match(regExp);
        if (valueMatch)
            return valueMatch[0];
        return value;
    }
    filterPackages(value) {
        let regExp = /(FIBRA|FTTH)/;
        if (value.match(regExp))
            return value.replace(this.regExpRemoveLetters, "");
        if (value.includes('FIBEXPLAY'))
            return value.replace('FIBEXPLAY', "").trim();
        return value;
    }
    scrollToTop() {
        this.content.scrollToTop(600); // goes to the top instead of instantly
    }
    handleShowScrollArrow(ev) {
        this.showScrollArrow = ev.detail.scrollTop > 0 ? true : false;
    }
}
