import { Component, Input, OnInit } from '@angular/core';
import { RoutingAppService } from '../../../services/routing-app.service';
import { IMenu } from '../../../interfaces/IMenu';

@Component({
  selector: 'app-footer-toolbar',
  templateUrl: './footer-toolbar.component.html',
  styleUrls: ['./footer-toolbar.component.scss'],
})
export class FooterToolbarComponent implements OnInit {

  @Input() showBtnPago: boolean = false;
  @Input() menu: any;

  subscriber: IMenu;
  reportPayment: IMenu;
  services: IMenu;
  
  constructor( 
    private _routing: RoutingAppService
  ) { 

  }

  ngOnInit() {
    this.subscriber = this.menu.find((option:IMenu) => option.url === '/rentcar')
    this.reportPayment = this.menu.find((option:IMenu) => option.url === '/favorites')
    this.services = this.menu.find((option:IMenu) => option.url === '/services')
  }

  goTo(route: string){
    this._routing.ChangeRuta(route)
  }
}
